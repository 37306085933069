<template>
    <div>
        <!-- Ajuda -->
        <center>
            <v-btn v-if="!show" text @click="show = !show"
            ><span>Mostrar Ajuda</span
            ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
        </center>
        <v-slide-y-transition>
            <v-card
            v-show="show"
            class="elevation-6 pa-3"
            style="border: 2px solid green !important;"
            color="grey lighten-3"
            >
            <v-row class="justify-center">
            <v-col cols="12">
                <span>
                    1. Nesta página tem acesso aos certificados de participação.
                </span>
            </v-col>
            </v-row>
            </v-card>
        </v-slide-y-transition>
        <v-container>
            <v-data-table
                        no-data-text="Não existem campeonatos neste momento."
                        hide-default-footer
                        :headers="headers" 
                        :items="campeonatos"
                        item-key="campeonatoid"
                        class="elevation-1"
                        color="#009263"
                        >
                        

                        <template v-slot:[`item.campeonato`]="{ item }">
                            {{ item.desc }}
                        </template>
                        <template v-slot:[`item.acoes`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="showModalCertificados = true, codCampeonato=item.id"
                                >
                                    <v-icon color="#009263">
                                    mdi-pencil
                                    </v-icon>
                                </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                    <EditarCertificadoCampeonato
                    :showModal="showModalCertificados"
                    :codCampeonato="codCampeonato"
                    @close="showModalCertificados = false"
                    />  
        </v-container>
    </div>
</template>


<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require('moment');
import EditarCertificadoCampeonato from "./EditarCertificadoCampeonato.vue";

export default {
    components: {
        EditarCertificadoCampeonato,        
    },
    data(){
        return {
            campeonatos: [],
            codCampeonato: '',
            showModalCertificados: false,
            show: false,

            dataNovosCertificados: new Date(2023,2,20),

            headers: [
                { text: "Campeonato", value: "campeonato", class: "title",style: "text-align: center", width:'100%'}, 
                { text: "Ações", value: "acoes", class: "subtitle-1"}
            ],
        }
    },
    methods: {

        async getCampeonatosForCertificates(){
            const response = await axios.get(host + "campeonatos-ids/proximoInativo?tipo=certificado")
            var data = response.data
            if(response.data.length > 0 || response.data["prox"].length > 0){
                let info = data["prox"]
                info.map(obj => {
                    let dataCampeonato = moment(obj.datai).isValid() ? moment(obj.datai).locale('pt').format('DD/MM/YYYY') : '';
                    let [day, month, year] = dataCampeonato.split('/').map(Number)
                    let dataC = new Date(year, month - 1, day);
                    if(dataC > this.dataNovosCertificados){
                        this.campeonatos.push(obj)
                    }

                })
            }

        },

        getEdicao(date){
          let data = moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY') : 'Indisponível';
          return ("Edição " + data)
        },
        
    },
    mounted(){
        this.getCampeonatosForCertificates()
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        }
    }
}

</script>

<style scoped>

</style>