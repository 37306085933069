<template>
    <v-dialog :value="showModal" @input="closeModal" 
              max-width="70%">
        <v-card>
            <v-card-title class="headline">Jogos Alvo</v-card-title>
            <v-card-text>
                <v-form @submit.prevent="saveTarget">
                    <v-select
                        v-model="jogoSelecionado"
                        label="Jogo"
                        :items="jogos"
                        required
                        @change="anoSelecionado = null"
                        ></v-select>
                    <v-file-input
                        v-model="image"
                        accept="image/*"
                        label="Upload ícone de jogo"
                        required
                    ></v-file-input>
                    <v-text-field
                        v-model="tipo"
                        label="Tipo"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="link"
                        label="Link"
                        required
                    >
                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn class="table-btn" color="#009263" @click="saveTarget">Guardar</v-btn>
                <v-btn class="table-btn" color="#C0C0C0" @click="closeModal">Fechar</v-btn>
            </v-card-actions>
            <div v-if="existeTargets" class="table-wrapper">
                <table>
                    <thead>
                        <tr>
                        <th>Jogo</th>
                        <th>Tipo</th>
                        <th>Link</th>
                        <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in targets" :key="index">
                        <td>{{ getJogoByID(item.jogo) }}</td>
                        <td>
                            <template v-if="editingIndex !== index">
                                {{ item.tipo }}
                            </template>
                            <v-text-field
                                v-else
                                v-model="tipoEditado"
                            ></v-text-field>
                        </td>
                        <td>
                            <template v-if="editingIndex !== index">
                                {{ item.link }}
                            </template>
                            <v-text-field
                                v-else
                                v-model="linkEditado"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-btn v-if="editingIndex !== index" @click="editingIndex = index" style="margin-right: 20px;">Editar</v-btn>
                            <v-btn v-if="editingIndex !== index" @click="deleteTarget(item.id); editingIndex = -1">Eliminar</v-btn>
                            
                            <v-btn v-else color="success" @click="saveEditedTarget(item); editingIndex = -1" style="margin-right: 20px;">Guardar</v-btn>
                            <v-btn v-if="editingIndex === index" @click="editingIndex = -1; tipoEditado=null; linkEditado=null">Cancelar</v-btn>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

export default {
    data(){
        return {
            jogoSelecionado: null,
            jogos: [],
            listaJogos: {},
            existeTargets: false,
            targets: {},
            link: null,
            image: null,
            tipo: null,

            tipoEditado: null,
            linkEditado: null,
            editingIndex: -1
        }
    },
    props: {
        showModal :{
            type: Boolean,
            required: true,
            default: false,
        },
        codCampeonato :{
            type: String,
            required: true
        }
    },
    methods:{
        closeModal(){
            this.jogoSelecionado = null
            this.image = null
            this.link = null
            this.tipo = null
            this.$emit("close");
        },

        saveEditedTarget(t){
            if(this.tipoEditado == null) this.tipoEditado = t.tipo
            if(this.linkEditado == null) this.linkEditado = t.link
            var formData = new FormData();
            let formEntries = {
                tipo: this.tipoEditado,
                link: this.linkEditado
            }

            formData.append('data',JSON.stringify(formEntries))
            axios.post(host + "target/editar/" + t.id, formData,{
                        headers:{
                            "Content-Type": 'multipart/form-data'
                        }
                    }
                    )
                .then(()=>{
                    Swal.fire({
                        icon: 'success',
                        title: 'Target registado com sucesso.',
                        confirmButtonColor: '#009263'
                    })
                    this.getTargets()
                })
                .catch((erro)=>{console.log(erro)})
            this.linkEditado = null
            this.tipoEditado = null
        },

        deleteTarget(id){
            axios.delete(host + "target/" + id)
                .then(()=>{
                    Swal.fire({
                            icon: 'success',
                            title: 'Target eliminado com sucesso.',
                            confirmButtonColor: '#009263'
                        })
                    this.getTargets()
                })
                .catch((err)=> {console.log(err)})
        },

        saveTarget(){
            
            if(this.jogoSelecionado != null && this.link != null && this.image != null){
                var anos = this.jogoSelecionado.split('(')[1].split('.')[0]
                let j = this.listaJogos[this.jogoSelecionado]

                // Verificar se o jogo já existe nos targets
                let found = false
                if(this.targets.length >= 1)
                    if(this.targets.find(item => item.jogo == j))
                        found = true
                

                if(!found){
                    if(this.tipo == null) this.tipo = ""
                    var formData = new FormData();
                    let formEntries = {
                        campeonatoID: this.codCampeonato,
                        jogoID: j,
                        tipo: this.tipo,
                        link: this.link,
                        ano: anos
                    }

                    formData.append('data',JSON.stringify(formEntries))
                    formData.append('files.icon',this.image)

                    axios.post(host + "target", formData,{
                        headers:{
                            "Content-Type": 'multipart/form-data'
                        }
                    }
                    )
                        .then(()=>{
                            Swal.fire({
                                icon: 'success',
                                title: 'Target registado com sucesso.',
                                confirmButtonColor: '#009263'
                            })
                            this.getTargets()
                        })
                        .catch((erro)=>{console.log(erro)})
                    this.jogoSelecionado = null
                    this.image = null
                    this.link = null
                    this.tipo = null
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Esse jogo já se encontra atribuído ao campeonato.",
                        confirmButtonColor: '#009263'
                    })
                    this.jogoSelecionado = null
                    this.image = null
                    this.link = null
                    this.tipo = null
                }
                
            }else {
                Swal.fire({
                    icon: "error",
                    title: "Por favor, preencha todos os campos.",
                    confirmButtonColor: '#009263'
                })
            }
        },

        async getJogos(){
            const data = await axios.get(host + "jogos")
            var response = data.data
            if(response != null){
                this.jogos = response.map((obj) => {
                    
                    this.$set(this.listaJogos, obj.texto, obj.jogo)

                    return obj.texto;
                })
                
            }
        },

        async getTargets(){
            const data = await axios.get(host + "target/" + this.codCampeonato)
            if(data != null)
                var ts = data.data[this.codCampeonato]
                if(ts != null && ts.length >= 1){
                    this.existeTargets = true;
                    this.targets = ts
                }
                else{
                    this.existeTargets = false;
                    this.targets = []
                }
        },

        getJogoByID(id){
            const jogos = {};
            for (const key in this.listaJogos) {
                jogos[this.listaJogos[key]] = key;
            }
            return jogos[id] || null;
        },
    },
    mounted() {
        this.getJogos()
    },
    watch:{
        showModal(val){
            if(val){
                this.targets = [];
                this.getTargets();
            }
        }
    },
}

</script>

<style scoped>
.table-wrapper {
  height: 300px; /* Set the height of the table */
  overflow-y: scroll; /* Enable vertical scrolling */
}

thead th {
  position: sticky;
  top: 0;
  background-color: white;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #f2f2f2;
}

th {
  font-weight: bold;
  padding: 12px;
  text-align: center;
}

td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.table-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}

/* tr:nth-child(odd) {
    background-color: #f2f2f2;
} */

tr:nth-child(even) {
    background-color: #F5F9F5;
}

</style>