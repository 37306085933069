<template>
    <div class="grey lighten-3">
      <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
            <v-card-title class="justify-center">
                    <h2 class="title-campeonato">{{"Campeonatos Hypatiamat"}}</h2>
                    <div v-if="existeProximoCampeonato && (edicao != null || !multipleCampeonatos)"
                    class="justify-right"
                    style="margin-left: 1.5em;"
                    >
                    <v-responsive aspect-ratio="1">
                        <v-img
                            v-if="municipio == null && comunidade == null"
                            :src="require('@/assets/campeonatos_nacional.jpeg')"
                            width="100px"
                            heigth="100px"
                            >
                        </v-img>
                        <v-img
                            v-else
                            :src="require('@/assets/campeonatos.jpeg')"
                            width="100px"
                            heigth="100px"
                            >
                        </v-img>
                    </v-responsive>
                    </div>
            </v-card-title>
            
        <v-combobox
            v-show="multipleCampeonatos"
            id="edicao"
            label="Campeonato"
            prepend-icon="mdi-magnify"
            v-model="edicao"
            style="width: 400px; margin-left: auto; margin-right: auto;"
            color="#009263"
            :items="getCampeonatos()"
            @change="loadingImages = true; loading=true; targets=[]; 
            top3Alunos = {}; top3LocalidadeJogos= {}; top3EscolaAlunos= {}; top3Localidade = {};jogos={}; 
            setCampeonato(); "
            >
        </v-combobox>
        <div v-if="edicao != null || !multipleCampeonatos">
            <v-container v-if="loading">
                    <center>
                        <v-img
                        :src="require('@/assets/loading.gif')"
                        width="150px"
                        heigth="150px"
                        >
                        </v-img>
                    </center>
            </v-container>
            <div v-else>
                    <div v-if="existeCampeonato">
                        <!-- Ajuda -->
                        <center>
                            <v-btn v-if="!show" text @click="show = !show"
                                ><span>Mostrar Ajuda</span
                                ><v-icon color="#009263"> mdi-help-circle </v-icon>
                            </v-btn>
                            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
                        </center>
                        <v-slide-y-transition>
                            <v-card
                                v-show="show"
                                class="elevation-6 pa-3"
                                style="border: 2px solid green !important;"
                                color="grey lighten-3"
                            >
                                <v-row class="justify-center">
                                    <v-col cols="12">
                                        <span>
                                        Com a realização dos Campeonatos Hypatiamat pretende-se fomentar a prática da aritmética mental que constitui o alicerce do cálculo mental.
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>Para isso, os alunos inscritos na Plataforma (associados a um professor) são convidados a explorar um conjunto de jogos sérios.</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            A participação dos alunos, neste campeonato, é realizada de acordo com o seu ano de escolaridade:
                                            <ul v-for="value in jogos" :key="value[0]">
                                                <li v-if="value[1].length == 2">
                                                    Alunos do <strong>{{ value[1][0] }}.º</strong> e <strong>{{ value[1][value[1].length - 1]}}.º</strong> ano: <strong>{{ value[0] }}</strong>
                                                </li>
                                                <li v-else-if="value[1].length > 2">
                                                    Alunos do <strong>{{ value[1][0] }}.º</strong> ao <strong>{{ value[1][value[1].length - 1]}}.º</strong> ano: <strong>{{ value[0] }}</strong>
                                                </li>
                                                <li v-else>
                                                    Alunos do <strong>{{ value[1][0] }}.º</strong> ano: <strong>{{ value[0] }}</strong>
                                                </li>
                                            </ul>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            Para que os CAMPEONATOS decorram com fairplay, é necessário cumprir algumas regras:
                                                <ul>
                                                    <li> Os participantes têm de estar registados na PLATAFORMA (associados a um professor); </li>
                                                    <li> Cada participante deve jogar APENAS com as suas credenciais; NÃO É PERMITIDO que jogue com as credenciais de outro participante; </li>
                                                    <li> Para cada aluno, só será contabilizada a pontuação MÁXIMA obtida no(s) dia(s) do Campeonato; </li>
                                                    <li> Qualquer anomalia ou falha técnica não será da responsabilidade do Hypatiamat; </li>
                                                    <li> Nesta edição todos os alunos terão acesso a um certificado de participação. </li>
                                                </ul>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            Apelamos ao fairplay, ao bom senso e à colaboração dos participantes, dos professores e dos encarregados de educação.
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            Incentivamos TODOS a prepararem-se convenientemente para este DESAFIO!
                                            Bom trabalho!
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-slide-y-transition>
                        <br />
                        <Timer align="center" :endDate="dataf" :sentence="'término'" :key="timerKey"/>     
                        <div align="center">
                            <h2>Todos participamos, todos ganhamos.</h2>
                        </div>
                        <br/>
                        <div class="stats-container">
                            <div class="stat-box">
                                <div class="title">AE com Mais Alunos Participantes</div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-trophy icon-golden-trophy</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("escola-alunos",0) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-gold-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("escola-alunos",1) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-silver-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("escola-alunos",2) }}
                                    </div>
                                </div>
                            </div>
                            <div class="stat-box">
                                <div class="title">Municípios com Mais Alunos Participantes</div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-trophy icon-golden-trophy</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("localidade",0) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-gold-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("localidade",1) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-silver-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("localidade",2) }}
                                    </div>
                                </div>
                            </div>
                            <div class="stat-box">
                                <div class="title">Municípios com Mais Jogos Concluídos</div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-trophy icon-golden-trophy</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("localidade-jogos",0) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-gold-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("localidade-jogos",1) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-silver-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("localidade-jogos",2) }}
                                    </div>
                                </div>
                            </div>
                            <div class="stat-box">
                                <div class="title">Alunos com Mais Jogos Concluídos</div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-trophy icon-golden-trophy</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("aluno",0) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-gold-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("aluno",1) }}
                                    </div>
                                </div>
                                <div class="stat-row">
                                    <div class="stat-icon">
                                        <v-icon>mdi mdi-medal icon-silver-medal</v-icon>
                                    </div>
                                    <div class="stat-text">
                                        {{ getTop3Value("aluno",2) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-card-text>
                            <div v-if="loadingImages">
                                <center>
                                    <v-img
                                    :src="require('@/assets/loading.gif')"
                                    width="150px"
                                    heigth="150px"
                                    >
                                    </v-img>
                                </center>
                            </div>
                            <div 
                            v-else
                            class="d-flex justify-center">
                                <v-col cols="10" sm="12" xs="12" md="10" lg="10" xl="10">
                                    <v-row class="d-flex justify-space-around">

                                        <v-col 
                                        v-for="item in targets"
                                        :key="item.jogo"
                                        class="d-flex child-flex"
                                        cols="12" sm="12" md="6" lg="4" xl="3"
                                        >
                                            <a :href=item.link target="_blank" style="text-decoration:none">
                                                <v-card 
                                                    class="rounded-lg ml-auto mr-auto mt-4" 
                                                    width="120" height="120" max-width="140" min-width="120"
                                                    >
                                                    <v-img :src=getImage(item.id)
                                                        cover
                                                        class="elevation-0"
                                                    />
                                                </v-card>
                                                <v-card-title 
                                                v-if="item.tipo != ''"
                                                color="#009263"
                                                style="justify-content: center; color:black; font-size: medium;"
                                                >
                                                    {{ getJogoName(item.jogo) }} - {{ item.tipo }}
                                                </v-card-title>
                                                <v-card-title 
                                                    v-else
                                                    style="justify-content: center; color:black; font-size: medium;"
                                                >
                                                    {{ getJogoName(item.jogo) }}
                                                </v-card-title>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </div>
                        </v-card-text>
                    </div>
                    <div v-else-if="existeProximoCampeonato">
                        <!-- Ajuda -->
                        <center>
                            <v-btn v-if="!show" text @click="show = !show"
                                ><span>Mostrar Ajuda</span
                                ><v-icon color="#009263"> mdi-help-circle </v-icon>
                            </v-btn>
                            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
                        </center>
                        <v-slide-y-transition>
                            <v-card
                                v-show="show"
                                class="elevation-6 pa-3"
                                style="border: 2px solid green !important;"
                                color="grey lighten-3"
                            >
                                <v-row class="justify-center">
                                    <v-col cols="12">
                                        <span>
                                        Com a realização dos Campeonatos Hypatiamat pretende-se fomentar a prática da aritmética mental que constitui o alicerce do cálculo mental.
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>Para isso, os alunos inscritos na Plataforma (associados a um professor) são convidados a explorar um conjunto de jogos sérios.</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            A participação dos alunos, neste campeonato, é realizada de acordo com o seu ano de escolaridade:
                                            <ul v-for="value in jogos" :key="value[0]">
                                                <li v-if="value[1].length == 2">
                                                    Alunos do <strong>{{ value[1][0] }}.º</strong> e <strong>{{ value[1][value[1].length - 1]}}.º</strong> ano: <strong>{{ value[0] }}</strong>
                                                </li>
                                                <li v-else-if="value[1].length > 2">
                                                    Alunos do <strong>{{ value[1][0] }}.º</strong> ao <strong>{{ value[1][value[1].length - 1]}}.º</strong> ano: <strong>{{ value[0] }}</strong>
                                                </li>
                                                <li v-else>
                                                    Alunos do <strong>{{ value[1][0] }}.º</strong> ano: <strong>{{ value[0] }}</strong>
                                                </li>
                                            </ul>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            Para que os CAMPEONATOS decorram com fairplay, é necessário cumprir algumas regras:
                                                <ul>
                                                    <li> Os participantes têm de estar registados na PLATAFORMA (associados a um professor); </li>
                                                    <li> Cada participante deve jogar APENAS com as suas credenciais; NÃO É PERMITIDO que jogue com as credenciais de outro participante; </li>
                                                    <li> Para cada aluno, só será contabilizada a pontuação MÁXIMA obtida no(s) dia(s) do Campeonato; </li>
                                                    <li> Qualquer anomalia ou falha técnica não será da responsabilidade do Hypatiamat; </li>
                                                    <li> Nesta edição todos os alunos terão acesso a um certificado de participação. </li>
                                                </ul>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            Apelamos ao fairplay, ao bom senso e à colaboração dos participantes, dos professores e dos encarregados de educação.
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>
                                            Incentivamos TODOS a prepararem-se convenientemente para este DESAFIO!
                                            Bom trabalho!
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-slide-y-transition>
                        <br />
                        <Timer align="center" :endDate="datai" :sentence="'início'" :key="timerKey"/>     
                        <div align="center">
                            <h2>{{ frase1 }}</h2>
                            <h2>{{ frase2 }}</h2>
                        </div>
                        <div v-if="loadingImages">
                            <center>
                                <v-img
                                :src="require('@/assets/loading.gif')"
                                width="150px"
                                heigth="150px"
                                >
                                </v-img>
                            </center>
                        </div>
                        <div 
                        v-else
                        class="d-flex justify-center">
                            <v-col cols="10" sm="12" xs="12" md="10" lg="10" xl="10">
                                <v-row class="d-flex justify-space-around">
                                    <v-col 
                                    v-for="item in targets"
                                    :key="item.jogo"
                                    class="d-flex child-flex"
                                    cols="12" sm="12" md="6" lg="4" xl="3"
                                    >
                                        <a :href=item.link target="_blank" style="text-decoration:none">
                                            <v-card 
                                                class="rounded-lg ml-auto mr-auto mt-4" 
                                                width="120" height="120" max-width="140" min-width="120"
                                                >
                                                <v-img :src=getImage(item.id)
                                                    cover
                                                    class="elevation-0"
                                                />
                                            </v-card>
                                            <v-card-title 
                                                v-if="item.tipo != ''"
                                                color="#009263"
                                                style="justify-content: center; color:black; font-size: medium;"
                                            >
                                                {{ getJogoName(item.jogo) }} - {{ item.tipo }}
                                            </v-card-title>
                                            <v-card-title 
                                                v-else
                                                style="justify-content: center; color:black; font-size: medium;"
                                            >
                                                {{ getJogoName(item.jogo) }}
                                            </v-card-title>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </div>
                    <div v-else>
                        <v-container align="center" justify="center">
                            <v-img
                            :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                            >
                                <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                                </template>
                            </v-img>
                            <h2 style="text-align: center">As próximas edições serão anunciadas brevemente... Está atento!</h2>
                        </v-container>
                    </div>
                </div>
            </div>
                
            </v-card>
        </v-container>
      </v-main>
    </v-app>
    </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require('moment');
import Timer from "@/components/Timer.vue"

export default {
    components: {
        Timer,
    },
    data(){
        return{
            loading: true,
            show: false,
            existeCampeonato: false,
            existeProximoCampeonato: false,
            multipleCampeonatos: false,
            frase1: null,
            frase2: null,
            datai: null,
            dataf: null,
            municipio: null,
            comunidade: null,
            targets : [],
            codCampeonato: null,
            imageURLS: {},
            loadingImages: true,

            edicao: null,
            campeonatos: [],
            infoCampeonatos: [],
            campeonatoInfo: {},

            top3Alunos: {},
            top3Localidade: {},
            top3LocalidadeJogos: {},
            top3EscolaAlunos: {},

            jogos: {},
            jogosBD: [],

            timerKey: 0
        }
    },
    props: {
    },
    methods: {

        getJogoName(jogo){
            let res = this.jogosBD.find(e => e.jogo == jogo)
            return res["texto"]
        },

        async getJogosBD(){
            const jogos = await axios.get(host + "jogos")
            this.jogosBD = jogos.data
        },
        
        getCampeonatos(){
            return this.campeonatos.map(c => { return c.edicao})
        },

        setCampeonato(){ 
            this.timerKey++           
            this.codCampeonato = this.campeonatos.find(o => o.edicao === this.edicao).cod
            this.campeonatoInfo = this.infoCampeonatos.find(info => info.id == this.codCampeonato)
            this.frase1 = this.campeonatoInfo.frase1
            this.frase2 = this.campeonatoInfo.frase2
            this.datai = this.campeonatoInfo.datai
            this.dataf = this.campeonatoInfo.dataf
            this.municipio = this.campeonatoInfo.municipio
            this.comunidade = this.campeonatoInfo.comunidade
            this.getTop3()
            this.getTargets()
            this.loading=false
        },
        
        getEdicao(date){
          let data = moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY') : 'Indisponível';
          return ("Edição " + data)
        },

        async getProximoCampeonato() {
            const data = await axios.get(host + "campeonatos-ids/proximoAtivo")
            let info = data.data["prox"]
            if(info != undefined){
                if(info.length == 1){
                    this.existeCampeonato = true;
                    let campeonato = data.data["prox"][0]
                    this.codCampeonato = campeonato.id
                    this.frase1 = campeonato.frase1
                    this.frase2 = campeonato.frase2
                    this.datai = campeonato.datai
                    this.dataf = campeonato.dataf
                    this.municipio = campeonato.municipio
                    this.comunidade = campeonato.comunidade
                    this.getTop3()
                    this.getTargets()
                    this.loading = false
                }else if(info.length > 1){
                    this.existeCampeonato = true
                    this.multipleCampeonatos = true
                    this.infoCampeonatos = info
                    this.campeonatos = info.map(c => {
                        return {edicao: c.desc, cod: c.id}
                    })
                }else{
                    console.log("nao ha nada")
                }
            }
            else{
                const newData = await axios.get(host + "campeonatos-ids/proximoInativo")
                let newInfo = newData.data["prox"]
                if(newInfo != undefined){
                    if(newInfo.length == 1){
                        let campeonato = newData.data["prox"][0]
                        this.existeProximoCampeonato = true
                        this.codCampeonato = campeonato.id
                        this.frase1 = campeonato.frase1
                        this.frase2 = campeonato.frase2
                        this.datai = campeonato.datai
                        this.municipio = campeonato.municipio
                        this.comunidade = campeonato.comunidade
                        this.getTargets()
                        this.loading = false
                    }else if(newInfo.length > 1){
                        this.existeProximoCampeonato = true
                        this.multipleCampeonatos = true
                        this.infoCampeonatos = newInfo
                        this.campeonatos = newInfo.map(c => {
                            return {edicao: c.desc, cod: c.id}
                        })
                    }
                }
                else{
                    this.loading = false 
                }
            }
        },
        async getTargets(){
            const data = await axios.get(host + "target/" + this.codCampeonato)
            const jogos = await axios.get(host + "jogos")
            let response = data.data
            let jogosInfo = jogos.data
            if(response != {}){
                this.targets = response[this.codCampeonato]
                if(this.targets != undefined){
                    this.targets.map(obj => {
                        var jogoTarget = jogosInfo.find(e => e.jogo == obj.jogo)
                        if(obj.tipo != ""){
                            if(this.jogos[jogoTarget.descricao + " - " + obj.tipo]){
                                this.jogos[jogoTarget.descricao + " - " + obj.tipo] = this.jogos[jogoTarget.descricao + " - " + obj.tipo].concat(obj.ano)
                            }else{
                                this.jogos[jogoTarget.descricao + " - " + obj.tipo] = [obj.ano]
                            }
                        }
                        else{
                            if(this.jogos[jogoTarget.descricao]){
                                this.jogos[jogoTarget.descricao] = this.jogos[jogoTarget.descricao].concat(obj.ano)
                            }else{
                                this.jogos[jogoTarget.descricao] = [obj.ano]
                            }
                        }
                    })
                    for (let key in this.jogos) {
                        this.jogos[key].sort(); 
                    }
                    this.jogos = Object.entries(this.jogos).sort((a,b) => {
                        return a[1][0] - b[1][0]
                    })
                    this.getTargetImg();
                }
                else{
                    this.loadingImages = false
                }
            }else{
                this.loadingImages = false
            }
        },

        async getTargetImg(){
            if(this.targets.length > 0){
                await Promise.all(this.targets.map( async (t) => {
                    const response = await axios.get(host + "target/img/" + t.id)
                    if(response.data != null && response.data[0]["url"] != "")
                        var src = host + response.data[0].url.substring(1)
                        this.imageURLS[t.id] = src
                }))
            }
            this.loadingImages = false;
        },

        getImage(id){
            return this.imageURLS[id]
        },

        async getTop3(){

            const infoCampeonato = await axios.get(host + "campeonatos/" + this.codCampeonato);
            const infoEscolas = await axios.get(host + "escolas");
            const infoAlunos = await axios.get(host + "alunos");


            if(infoEscolas.data != null && infoAlunos.data != null && Object.keys(infoCampeonato.data).length > 0){
                let info = infoCampeonato.data
                let escs = infoEscolas.data["escolas"]
                let as = infoAlunos.data["alunos"]

                var statsPorAluno = {}
                var statsPorEscola = {}
                var statsPorLocalidade = {}

                if(info != undefined){

                    info.map(obj => {
    
                        let esc = obj.escola
                        let loc = escs.find(e => e.nome === obj.escola).localidade
                        let a = as.find(e => e.aluno === obj.nome).aluno

                        if(statsPorEscola[esc.replace(/Agrupamento de Escolas/g, "AE")]){

                            statsPorEscola[esc.replace(/Agrupamento de Escolas/g, "AE")]["totalAlunos"] += 1,
                            statsPorEscola[esc.replace(/Agrupamento de Escolas/g, "AE")]["totalPontuacao"] += obj.pontuacao,
                            statsPorEscola[esc.replace(/Agrupamento de Escolas/g, "AE")]["totalJogos"] += obj.njogos

                        }else{

                            statsPorEscola[esc.replace(/Agrupamento de Escolas/g, "AE")] = {
                                    "totalAlunos": 1,
                                    "totalPontuacao": obj.pontuacao,
                                    "totalJogos": obj.njogos
                                }

                        }
    
                        // Estatisticas por Municipio
                        if(statsPorLocalidade[loc]){
    
                            statsPorLocalidade[loc]["totalAlunos"] += 1,
                            statsPorLocalidade[loc]["totalPontuacao"] += obj.pontuacao,
                            statsPorLocalidade[loc]["totalJogos"] += obj.njogos
    
                        }else{
    
                            statsPorLocalidade[loc] = {
                            "totalAlunos": 1,
                            "totalPontuacao": obj.pontuacao,
                            "totalJogos": obj.njogos
                            }
    
                        }
    
                        if(statsPorAluno[a]){
                            statsPorAluno[a]["totalPontuacao"] += obj.pontuacao,
                            statsPorAluno[a]["totalJogos"] += obj.njogos
                        }else{
                            statsPorAluno[a] = {
                            "totalPontuacao": obj.pontuacao,
                            "totalJogos": obj.njogos
                            }
                        }
    
                    })
                }
                let alunosArray = Object.entries(statsPorAluno);
                let localidadesArray = Object.entries(statsPorLocalidade)
                let escolasArray = Object.entries(statsPorEscola)
                
                let escolasAlunos = escolasArray.sort((a,b) => b[1].totalAlunos - a[1].totalAlunos)
                let municipiosJogos = localidadesArray.sort((a,b) => b[1].totalJogos - a[1].totalJogos)
                alunosArray.sort((a, b) => b[1].totalJogos - a[1].totalJogos);
                localidadesArray.sort((a,b) => b[1].totalAlunos - a[1].totalAlunos)
    
                this.top3Alunos = Object.fromEntries(alunosArray.slice(0,3));
                this.top3Localidade = Object.fromEntries(localidadesArray.slice(0,3));
                this.top3LocalidadeJogos = Object.fromEntries(municipiosJogos.slice(0,3));
                this.top3EscolaAlunos = Object.fromEntries(escolasAlunos.slice(0,3));
            }

            
        },

        getTop3Value(type,index){
            switch(type){
                case "localidade":
                    if(Object.entries(this.top3Localidade)[index] != undefined)
                        return Object.entries(this.top3Localidade)[index][0]
                    else
                        break
                case "aluno":
                    if(Object.entries(this.top3Alunos)[index] != undefined)
                        return Object.entries(this.top3Alunos)[index][0]
                    else
                        break
                case "localidade-jogos":
                    if(Object.entries(this.top3LocalidadeJogos)[index] != undefined)
                        return Object.entries(this.top3LocalidadeJogos)[index][0]
                    else
                        break
                case "escola-alunos":
                    if(Object.entries(this.top3EscolaAlunos)[index] != undefined)
                        return Object.entries(this.top3EscolaAlunos)[index][0]
                    else
                        break
                default:
                    break
            }
        }

    },
    created() {
        this.getProximoCampeonato()
        this.getJogosBD()
    },
}

</script>

<style scoped>

.v-img{
    box-shadow: none !important;
}
.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.stat-box {
  min-width: 500px;
  height: 200px;
  background-color: #fff;
  border: 0.5px solid #ccc;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.stat-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
  flex: 1;
  width: auto;
}

.stat-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.stat-icon {
  margin-right: 10px;
  font-size: 1.5em;
}
.stat-text {
  flex: 1;
  font-size: 1.2em;
}

.stats-container > .stat-box:not(:last-child) {
  margin-right: 0;
}

/* media query for screens wider than 768px */
@media (min-width: 768px) {
  .stat-box {
    width: calc(25% - 20px); /* adjust to fit your design */
  }
}

/* media query for screens wider than 1024px */
@media (min-width: 1024px) {
  .stat-box {
    width: calc(20% - 20px); /* adjust to fit your design */
  }
}

/* Mobile devices (portrait) */
@media only screen and (max-width: 768px) {
  .stats-container {
    flex-direction: column;
    align-items: center;
  }
  
  .stat-box {
    min-width: unset;
    width: 90%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .stat-title {
    font-size: 1.1em;
    margin-bottom: 5px;
  }
  
  .stat-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .stat-icon {
    margin-right: 0;
    margin-bottom: 5px;
  }
  
  .stat-text {
    font-size: 1em;
    text-align: center;
  }
  
  .stats-container > .stat-box:not(:last-child) {
    margin-right: 0;
  }
}

.title-campeonato{
    color:#009263; 
    max-width: 100%; 
    font-size: 2.5rem;
}

@media screen and (max-width: 320px) {
  .title-campeonato {
    font-size: 1.4rem;
    max-width: 90%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 321px) and (max-width: 378px) {
  .title-campeonato {
    font-size: 1.6rem;
    max-width: 80%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  .title-campeonato {
    font-size: 1.8rem;
    max-width: 80%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 415px) and (max-width: 568px) {
  .title-campeonato {
    font-size: 1.8rem;
    max-width: 100%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

.icon-golden-trophy {
    color: gold;
  }

.icon-gold-medal {
    color: #FFD700;
}

.icon-silver-medal {
    color: #C0C0C0;
}

</style>