<template>
    <div class="grey lighten-3">
      <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
            <v-card-title class="d-flex justify-center">
              <div class="d-flex justify-start align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="returnCampeonatos"
                      color="#009263"
                      fab dense large
                      class="ml-n4 white--text mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon x-large>mdi-chevron-double-left</v-icon>
                    </v-btn>
                  </template>
                  <span style="text-align:left;">Voltar a Campeonatos</span>
                </v-tooltip>
              </div>
              <h2 class="title-campeonato" style="color: #009263; text-align:center; white-space: normal;">{{ getCampeonatoName }}</h2>
            </v-card-title>
            <!-- Ajuda -->
            <center>
              <v-btn v-if="!show" text @click="show = !show"
                ><span>Mostrar Ajuda</span
                ><v-icon color="#009263"> mdi-help-circle </v-icon>
              </v-btn>
              <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
            </center>
            <v-slide-y-transition>
              <v-card
                v-show="show"
                class="elevation-6 pa-3"
                style="border: 2px solid green !important;"
                color="grey lighten-3"
              >
                <v-row class="justify-center">
                  <v-col cols="12">
                    <span>
                      1. Nesta página tens acesso à lista de participantes e respetiva classificação por jogo e a um conjunto de estatísticas.
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <span>
                      2. As estatísticas podem ser realizadas por AGRUPAMENTO (Geral ou por Jogo) ou MUNICÍPIO (Geral ou por Jogo) e com os seguintes critérios: Número de Jogos, Número de alunos ou Pontuação média.
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-slide-y-transition>
            <br />
            <v-container v-if="loading">
              <center>
                <v-img
                  :src="require('@/assets/loading.gif')"
                  width="150px"
                  heigth="150px"
                >
                </v-img>
              </center>
            </v-container>
            <div v-else>
              <v-row class="info-box">
                <v-col class="info-col">
                  <span><b>Alunos: </b>  {{ this.statsGerais.totalAlunos }}</span>
                </v-col>
                <v-col class="info-col">
                  <span><b>Jogos: </b>  {{ this.statsGerais.totalJogos }}</span>
                </v-col>
                <v-col class="info-col">
                  <span><b>Agrupamentos: </b>  {{ this.statsGerais.totalAgrupamentos }}</span>
                </v-col>
                <v-col class="info-col">
                  <span><b>Municípios: </b> {{ this.statsGerais.totalMunicipios }}</span>
                </v-col>
                <v-col class="info-col">
                  <span><b>Rácio: </b> {{ (this.statsGerais.totalJogos / this.statsGerais.totalAlunos).toFixed(2) }}</span>
                </v-col>
                <!-- <v-col class="info-col">
                  <span><b>AE com Mais Jogos: </b> {{ this.statsGerais.agrupamentoJogos }}</span>
                </v-col>
                <v-col class="info-col">
                  <span><b>Município com Mais Jogos: </b> {{ this.statsGerais.municipioJogos }}</span>
                </v-col> -->
              </v-row>
              <v-row justify="center" align="center">
                  <v-btn
                    class="mx-4 mb-4"
                    color="#009263"
                    @click="showRanking= false, showStats = !showStats"
                  >
                    <span style="color:white"> Estatísticas </span>
                  </v-btn>
                  <v-btn 
                    class="mx-4 mb-4"
                    color="#009263"
                    @click="showStats= false, showRanking = !showRanking"
                  >
                    <span style="color:white"> Classificação </span>
                  </v-btn>
                </v-row>
                <Estatisticas v-if="showStats" :cod="codCampeonato"/>
                <Ranking v-if="showRanking" :cod="codCampeonato"/>
            </div>
  
          </v-card>
        </v-container>
      </v-main>
    </v-app>
    </div>
</template>
  
<script>
import Ranking from "@/components/Ranking.vue"
import Estatisticas from "@/components/Estatisticas.vue";
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

export default {
    components: {
      Ranking,
      Estatisticas,
    },
    data() {
      return {
        loading: true,
        show: false,
        showStats: false,
        showRanking: false,
        codCampeonato: null,
        edicao: null,
        statsGerais: {
          totalAlunos: 0,
          totalJogos: 0,
          totalAgrupamentos: 0,
          totalMunicipios: 0,
          // agrupamentoJogos: "",
          // municipioJogos: ""
        },
      };
    },
    props: {
      lastPage:{ // navigate back
        type: Number,
        default: 1
      },
    },
    methods: {
      setCodCampeonato(){
        this.codCampeonato = this.$route.params.codcampeonato
        this.loading=false;
      },
  
  
      returnCampeonatos(){
        let page_before = this.lastPage
        this.$router.push({
          name: "Campeonatos",
          params: {page_before}
        })
      },

      async getStatsCampeonato(){
        const campeonatoData = await axios.get(host + "campeonatos/" + this.codCampeonato)
        const infoEscolas = await axios.get(host + "escolas");
        var escs = infoEscolas.data["escolas"]
        var localidades = []
        var escolas = []
        if(campeonatoData.data != undefined){
          var data = campeonatoData.data
          // var municipiosJogos = {}
          // var agrupamentosJogos = {}
          data.map(obj => {
            let loc = escs.find(e => e.nome == obj.escola).localidade
            if(!(localidades.find(e => e === loc))) localidades.push(loc)
            if(!(escolas.find(e => e === obj.escola))) escolas.push(obj.escola)
            
            // if(agrupamentosJogos[obj.escola.replace(/Agrupamento de Escolas/g, "AE")]){
            //   agrupamentosJogos[obj.escola.replace(/Agrupamento de Escolas/g, "AE")] += obj.njogos
            // }else{
            //   agrupamentosJogos[obj.escola.replace(/Agrupamento de Escolas/g, "AE")] = obj.njogos
            // }

            // if(municipiosJogos[loc]){
            //   municipiosJogos[loc] += obj.njogos
            // }else{
            //   municipiosJogos[loc] = obj.njogos
            // }
            
            this.statsGerais.totalAlunos += 1
            this.statsGerais.totalJogos += obj.njogos
          })
          this.statsGerais.totalAgrupamentos += escolas.length
          this.statsGerais.totalMunicipios += localidades.length

          // let arrayMunicipios = Object.entries(municipiosJogos)
          // let arrayAgrupamentos = Object.entries(agrupamentosJogos)

          // this.statsGerais.municipioJogos = arrayMunicipios.sort((a,b) => b[1] - a[1])[0][0]
          // this.statsGerais.agrupamentoJogos = arrayAgrupamentos.sort((a,b) => b[1] - a[1])[0][0]
        }

      }
      
  
    },
    computed: {
      getCampeonatoName(){
        if(this.$route.params.edicao) return this.$route.params.edicao
        else return this.edicao
      },
      isLoggedIn() {
        return this.$store.getters.isAuthenticated;
      },
    },
  
    async mounted() {
      this.setCodCampeonato()
      this.getStatsCampeonato()
    },
    created() {
      this.edicao = sessionStorage.getItem("edicao")
    }
  };
</script>

<style scoped>
  .info-box {
    display: flex;
    justify-content: space-around; 
    align-items: center;
    border-radius: 10px;
    border-color: #009263; 
    border-width: 1px; 
    border-style: solid; 
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    /* padding: 16px; */
    margin: auto;
    max-width: 900px;
  }

  .info-col {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: auto;
  }

  @media only screen and (max-width: 480px) {
  .info-box {
    flex-direction: column;
  }
}

.title-campeonato{
    color:#009263; 
    max-width: 100%; 
    font-size: 2.5rem;
}

@media screen and (max-width: 320px) {
  .title-campeonato {
    font-size: 1.4rem;
    max-width: 100%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 321px) and (max-width: 378px) {
  .title-campeonato {
    font-size: 1.6rem;
    max-width: 90%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  .title-campeonato {
    font-size: 1.8rem;
    max-width: 90%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 415px) and (max-width: 568px) {
  .title-campeonato {
    font-size: 1.8rem;
    max-width: 100%;
    /* white-space: normal; */
    word-break: break-word;
  }
}
</style>