<template>
    <div>
        <center>
            <v-btn v-if="!show" text @click="show = !show"
                ><span>Mostrar Ajuda</span
                ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
        </center>
        <v-slide-y-transition>
            <v-card
                v-show="show"
                class="elevation-6 pa-3"
                style="border: 2px solid green !important;"
                color="grey lighten-3"
            >
                <v-row class="justify-center">
                    <v-col cols="12">
                        <span>
                        1. Esta página contém os certificados de participação nos campeonatos.
                        </span>
                    </v-col>
                </v-row>
            </v-card>
        </v-slide-y-transition>
        <br/>
        <div v-if="temCampeonatosParticipados"
            class="campeonato-list-container"
        >
            <div class="campeonato-list">
                <div class="campeonato-row" v-for="(campeonato,index) in campeonatos" :key="index" :class="{ 'last-row': index === campeonatos.length - 1 }">
                    <div class="campeonato-name">{{campeonato.desc}}</div>
                    <v-btn
                        class="download-button"
                        color="#009263"
                        @click="downloadCertificado(campeonato.campeonato)"
                    >
                    <span style="color:white">Descarregar</span>
                    </v-btn>
                </div>
            </div>
        </div>
        <div v-else>
            <v-container align="center" justify="center">
                <v-img
                :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                >
                </v-img>
                <h2 style="text-align: center">De momento não tem certificados disponíveis.</h2>
            </v-container>
        </div> 
    </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require('moment');
const jsPDF = require('jspdf');

export default {
    data(){
        return{
            userID: null,
            temCampeonatosParticipados: false,
            campeonatos: [],
            show: false,
        }
    },
    methods: {

        async getCampeonatosParticipados(){
            const dataCampeonatos = await axios.get(host + "campeonatos/participou/" + this.userID);
            let infoCampeonatos = dataCampeonatos.data["campeonatos"]
            
            if(infoCampeonatos.length > 0){
                this.campeonatos = infoCampeonatos
                this.temCampeonatosParticipados = true
            }
        },

        async downloadCertificado(campeonato){
            const infoCertificado = await axios.get(host + "certificados-infos/" + campeonato);
            const infoAluno = await axios.get(host + "alunos/" + this.userID)
            const dataCampeonatos = await axios.get(host + "campeonatos/" + campeonato);
            const campeonatoTargets = await axios.get(host + "target/" + campeonato)
            
            
            var dataC = dataCampeonatos.data
            var dataA = infoAluno.data
            var dataT = campeonatoTargets.data[campeonato]
            
            
            var resAlunoCampeonato = dataC.find(e => e.nome == dataA.nome)
            var campeonatoInfo = this.campeonatos.find(e => e.campeonato === campeonato)
            const infoJogo = await axios.get(host + "jogos/" + resAlunoCampeonato.jogo)
            const resultadosCampeonato = await axios.get(host + "campeonatos/" + campeonato + "?jogo=" + resAlunoCampeonato.jogo)
            dataC = resultadosCampeonato.data
            var dataJ = infoJogo.data
            var jogoCampeonato = dataT.find(e => e.jogo == resAlunoCampeonato.jogo)
            if(dataC.length > 0){
                dataC = dataC.sort((a, b) => {
                    if (a.pontuacao == b.pontuacao)
                        return a.njogos - b.njogos
                    return b.pontuacao - a.pontuacao
                });

                for(let i=0; i < dataC.length - 1; i++){

                    if(dataC[i].pontuacao == dataC[i+1].pontuacao){
                        
                    dataC[i+1].classificacao = parseInt(dataC[i].classificacao)
                    }
                    else{

                    dataC[i+1].classificacao = parseInt(dataC[i].classificacao) + 1

                    }
                }
            }
            resAlunoCampeonato = dataC.find(e => e.nome == dataA.nome)
            let info = infoCertificado.data
            if(info.length == 1){
                const id = info[0].id
                const frase = info[0].frase
                const bannersInfo = await axios.get(host + "certificados-infos/banners/" + id)
                let bannersData = bannersInfo.data["urls"]
                if(bannersData.length == 2){

                    const banner1url = bannersData.find(e => e.banner === "banner1").url
                    const banner2url = bannersData.find(e => e.banner === "banner2").url

                    let anoJogo = dataJ.texto.split('(')[1].split('.')[0]

                    const di = new Date(campeonatoInfo.di);
                    const df = new Date(campeonatoInfo.df);

                    const diDay = di.getDate();
                    const dfDay = df.getDate();
                    const diMonth = di.toLocaleString('pt-PT', { month: 'long' });
                    const dfMonth = df.toLocaleString('pt-PT', { month: 'long' });
                    const year = di.getFullYear();

                    let dateString;

                    if (diMonth !== dfMonth) {
                        dateString = `${diDay} de ${diMonth} a ${dfDay} de ${dfMonth} de ${year}`;
                    } else if ((df - di) / (1000 * 60 * 60 * 24) >= 2) {
                        dateString = `${diDay} a ${dfDay} de ${diMonth} de ${year}`;
                    } else {
                        if(diDay == dfDay)
                            dateString = `${dfDay} de ${dfMonth} de ${year}`
                        else
                            dateString = `${diDay} e ${dfDay} de ${diMonth} de ${year}`;
                    }

                    let completeText;
                    if(resAlunoCampeonato.classificacao == "1"){
                        if(jogoCampeonato.tipo != ""){
                            completeText = "Certifica-se que " + dataA.nome + " foi a(o) campeã(o) na " + frase + " - "  + dataJ.descricao +
                            " - " + jogoCampeonato.tipo + ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString + "."
                        }else{
                            completeText = "Certifica-se que " + dataA.nome + " foi a(o) campeã(o) na " + frase + " - "  + dataJ.descricao +
                            ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString + "."
                        }
                    }else{
                        if(jogoCampeonato.tipo != ""){
                            completeText = "Certifica-se que " + dataA.nome + " participou na " + frase + " - "  + dataJ.descricao +
                            " - " + jogoCampeonato.tipo + ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString
                            + ", tendo ficado(a) colocado(a) na " + resAlunoCampeonato.classificacao + ".ª posição."     
                        }else{
                            completeText = "Certifica-se que " + dataA.nome + " participou na " + frase + " - "  + dataJ.descricao +
                            ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString
                            + ", tendo ficado(a) colocado(a) na " + resAlunoCampeonato.classificacao + ".ª posição."     
                        }
                    }
                    let finalDate = "Braga, " + dfDay + " de " + dfMonth + " de " + year + "."
                    
                    const doc = new jsPDF.jsPDF('landscape');
                    doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'S', 'g');
                    doc.addImage(host + banner1url.slice(1), 'JPEG', 5, 5, doc.internal.pageSize.width - 10, 30);
                    
                    doc.setFontSize(18);
                    doc.setFont('helvetica', 'normal');


                    const textWidth = doc.internal.pageSize.width * 0.8;
                    const textHeight = doc.internal.getFontSize() * 0.8;
                    const lines = doc.splitTextToSize(completeText, textWidth);
                    let textY = (doc.internal.pageSize.height - (lines.length * textHeight)) / 2 - 30;
                    doc.text(completeText,(doc.internal.pageSize.width - textWidth) / 2, textY,{
                            maxWidth: textWidth,
                            lineHeightFactor: 2,
                            align: "justify"
                        })
                    
                    for (let i = 0; i < lines.length; i++) {
                        textY += textHeight
                    }

                    doc.text(finalDate,(doc.internal.pageSize.width) / 2 - 40, textY + 3)
  
                    doc.addImage(host + banner2url.slice(1), 'JPEG', 0, doc.internal.pageSize.height - 100, doc.internal.pageSize.width, 100);

                    const pdfContent = doc.output('bloburl');

                    const blob = await fetch(pdfContent).then(res => res.blob());
                    
                    const url = URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'Certificado.pdf';

                    // Download
                    a.click();
                }
            }
        },

        getEdicao(date){
          let data = moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY') : 'Indisponível';
          return ("Edição " + data)
        },

    },
    async created(){
        this.userID = this.$store.getters.getUserId;
    },
    mounted(){
        this.getCampeonatosParticipados();
    }

}

</script>

<style scoped>
.campeonato-list-container {
   border: 1px solid #ccc;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
   overflow: hidden;
 }
 .campeonato-list {
     height: auto;
     overflow-y: scroll;
     width: 98%; 
     margin: auto; 
 }
 .campeonato-row {
     display: flex;
     align-items: center;
     padding: 10px;
     border-bottom: 1px solid #ccc;
 }

 .last-row {
   border-bottom: none;
 }
 .campeonato-name {
     flex: 1;
     margin-right: 10px;
 }

 .download-button {
   background-color: #2196f3;
   color: white;
   border: none;
   padding: 5px 10px;
   border-radius: 5px;
   cursor: pointer;
 }

</style>