<template>
    <v-dialog :value="showModal" @input="closeModal" 
              max-width="50%">
        <v-card>
            <v-card-title class="headline">Informação do Certificado</v-card-title>
            <v-card-text>
                <v-form @submit.prevent="saveTarget">
                    <v-file-input
                        v-model="banner1"
                        accept="image/*"
                        label="Upload banner do topo do Certificado"
                        required
                        v-if="banner1url == null"
                    ></v-file-input>
                    <v-label
                        v-if="banner1url != null"
                    >
                        Banner 1
                    </v-label>
                    <v-img
                        label="Banner 1"
                        v-if="banner1url != null"
                        :src="banner1url"
                    ></v-img>
                    <v-text-field
                        v-model="frase"
                        required
                        label="Frase do Campeonato"
                        v-if="fraseExistente == null"
                    >
                    </v-text-field>
                    <v-text-field
                        v-if="fraseExistente != null"
                        v-model="fraseExistente"
                        label="Frase do Campeonato"
                        readonly
                    >
                    </v-text-field>
                    <v-file-input
                        v-model="banner2"
                        accept="image/*"
                        label="Upload banner do fundo do Certificado"
                        required
                        v-if="banner2url == null"
                    ></v-file-input>
                    <v-label
                        v-if="banner2url != null"
                    >
                        Banner 2
                    </v-label>
                    <v-img
                        v-if="banner2url != null"
                        :src="banner2url"
                    ></v-img>
                </v-form>
            </v-card-text>
            <v-card-actions v-if="!certificadoExistente">
                <v-btn class="table-btn" color="#009263" @click="saveCertificadoInfo">Guardar</v-btn>
                <v-btn class="table-btn" color="#C0C0C0" @click="closeModal">Fechar</v-btn>
            </v-card-actions>
            <v-card-actions v-else>
                <v-btn class="table-btn" color="#009263" @click="deleteCertificadoInfo">Refazer</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

export default {
    data(){
        return {
            frase: null,
            banner1: null,
            banner2: null,

            certificadoExistente: false,
            banner1url: null,
            banner2url: null,
            fraseExistente: null,
            certificado : null,

        }
    },
    props: {
        showModal :{
            type: Boolean,
            required: true,
            default: false,
        },
        codCampeonato :{
            type: String,
            required: true
        }
    },
    methods:{
        closeModal(){
            this.banner1 = null
            this.banner2 = null
            this.frase = null
            this.banner1url = null
            this.banner2url = null
            this.fraseExistente = null
            this.certificadoExistente = false
            this.$emit("close");
        },

        async getCertificadoInfo(){
            const response = await axios.get(host + "certificados-infos/" + this.codCampeonato)
            let info;
            if(Object.keys(response.data).length > 0 )
                info = response.data[0]

            if(info != undefined){
                const banners = await axios.get(host + "certificados-infos/banners/" + info.id)

                let bannersInfo;
                if(Object.keys(banners.data).length > 0)
                    bannersInfo = banners.data["urls"]
                
                if(bannersInfo != undefined){
                    this.certificado = info.id
                    this.banner1url = host + bannersInfo.find(e => e.banner === "banner1").url.slice(1)
                    this.banner2url = host + bannersInfo.find(e => e.banner === "banner2").url.slice(1)
                    this.fraseExistente = info.frase
                    this.certificadoExistente = true
                }
                
            }

        },

        async deleteCertificadoInfo(){
            axios.delete(host + "certificados-infos/" + this.certificado)
                .then(()=>{
                    Swal.fire({
                            icon: 'success',
                            title: 'Informação do certificado eliminada com sucesso.',
                            confirmButtonColor: '#009263'
                        })
                    this.certificado = null
                    this.banner1url = null
                    this.banner2url = null
                    this.fraseExistente = null
                    this.certificadoExistente = false
                })
                .catch((err)=> {console.log(err)})
            
        },

        saveCertificadoInfo(){
            if(this.banner1 != null && this.frase != null && this.banner2 != null){


                var formData = new FormData();
                let formEntries = {
                    campeonato: this.codCampeonato,
                    frase: this.frase
                }

                formData.append('data',JSON.stringify(formEntries))
                formData.append('files.banner1',this.banner1)
                formData.append('files.banner2',this.banner2)

                axios.post(host + "certificados-infos", formData,{
                    headers:{
                        "Content-Type": 'multipart/form-data'
                    }
                }
                )
                    .then(()=>{
                        Swal.fire({
                            icon: 'success',
                            title: 'Informação do certificado registada com sucesso.',
                            confirmButtonColor: '#009263'
                        })
                        this.getCertificadoInfo()
                    })
                    .catch((erro)=>{console.log(erro)})
                this.banner1 = null
                this.frase = null
                this.banner2 = null
            }else {
                Swal.fire({
                    icon: "error",
                    title: "Por favor, preencha todos os campos.",
                    confirmButtonColor: '#009263'
                })
            }
        },

    },
    watch:{
        showModal(val){
            if(val){
                this.getCertificadoInfo();
            }

        }
    },
}

</script>

<style scoped>
.table-wrapper {
  height: 300px;
  overflow-y: scroll; 
}

thead th {
  position: sticky;
  top: 0;
  background-color: white;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #f2f2f2;
}

th {
  font-weight: bold;
  padding: 12px;
  text-align: left;
}

td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}
</style>