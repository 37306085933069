<template>
  <div v-if="!loading">
    <div>
      <div class="text-right">
        <v-btn
          class="mx-4 mb-4"
          color="#009263"
          @click="filtrosOpen = !filtrosOpen"
        >
        <v-icon style="color: white">mdi mdi-filter</v-icon>
        <span style="color: white">Filtros</span>
        </v-btn>
      </div>
      <div v-if="filtrosOpen">
        <v-combobox
              id="escolha"
              label="Filtrar por"
              prepend-icon="mdi-magnify"
              v-model="escolha1"
              style="width: 200px; margin-left: auto;"
              color="#009263"
              :items="escolhas1"
              @change="jogo='';escolha2=null;escolha3=null;infoTabela=[]"
        >
        </v-combobox>
        <v-combobox
              v-show="escolha1 != null"
              id="escolha"
              label="Visualizar Estatísticas"
              prepend-icon="mdi-magnify"
              v-model="escolha2"
              style="width: 200px; margin-left: auto;"
              color="#009263"
              :items="escolhas2"
              @change="infoTabela=[];escolha3=null;"
        >
        </v-combobox>
        <v-combobox
              v-show="escolha2 === 'Por Jogo'"
              id="jogo"
              label="Jogo"
              prepend-icon="mdi-gamepad-variant"
              v-model="jogo"
              style="width: 200px; margin-left: auto;"
              color="#009263"
              :items="jogos"
              @change="escolha3=null;infoTabela=[];"
        ></v-combobox>
        <v-combobox
              v-show="escolha2 === 'Gerais' || (escolha2 === 'Por Jogo' && jogo != null)"
              id="escolha3"
              label="Critério"
              prepend-icon="mdi mdi-table-filter"
              v-model="escolha3"
              style="width: 200px; margin-left: auto;"
              color="#009263"
              :items="escolhas3"
        ></v-combobox>
      </div>
    </div>
    <div>
      <v-text-field
                    v-model="filtrar"
                    label="Filtrar"
                    prepend-icon="mdi-magnify"
                    color="#009263"
                    single-line
        ></v-text-field>
      <v-data-table
            no-data-text="Não existem estatísticas a apresentar neste momento."
            :footer-props="{
                'items-per-page-text': 'Mostrar',
                'items-per-page-options': [15, 25, 35, -1],
                'items-per-page-all-text': 'Todos',
            }"
            dense
            :no-filter=true
            :items-per-page=15
            :headers="headers" 
            :items="infoTabela"
            item-key="nome"
            class="elevation-1 table-striped"
            color="#009263"
            :search="filtrar"
            >
            <!-- <template v-slot:[`item.classificacao`]="{ item }">
                <div class="justify-center">
                  <v-icon>{{ getIcon(item.posicao) }}</v-icon>
                  {{ item.posicao }}
                </div>
            </template>
            <template v-slot:[`item.nome`]="{ item }">
                {{ item.nome }}
            </template>
            <template v-slot:[`item.criterio`]="{ item }">
                <div v-if="escolha3=='Número de Jogos'">
                  {{ item.totalJogos }}
                </div>
                <div v-else-if="escolha3=='Número de Alunos'">
                  {{ item.totalAlunos }}
                </div>
                <div v-else-if="escolha3=='Pontuação Média'">
                  {{ (item.totalPontuacao / item.totalAlunos).toFixed(0) }}
                </div>
            </template> -->
            <template v-slot:item="{ item, index }">
                <tr :class="index % 2 === 0 ? 'even-row' : 'odd-row'">
                    <td>
                        <v-icon>{{ getIcon(item.posicao) }}</v-icon>
                        {{ item.posicao }}
                    </td>
                    <td>{{ item.nome }}</td>
                    <td v-if="escolha3=='Número de Jogos'">
                      {{ item.totalJogos }}
                    </td>
                    <td v-else-if="escolha3=='Número de Alunos'">
                      {{ item.totalAlunos }}
                    </td>
                    <td v-else-if="escolha3=='Pontuação Média'">
                      {{ (item.totalPontuacao / item.totalAlunos).toFixed(0) }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
  </div>
  <div v-else>
    <v-container>
      <center>
        <v-img
          :src="require('@/assets/loading.gif')"
          width="150px"
          heigth="150px"
        >
        </v-img>
      </center>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

export default {
    data() {
      return {
        filtrosOpen: false,
        codCampeonato: null,
        escolas: [],
        localidades: [],
        statsPorLocalidade: {},
        statsPorEscola: {}, 
        headers: [
          { text: "", value: "classificacao", class: "subtitle-1" ,width:'7%', sortable:false},
          { text: "Nome", value: "nome", class: "subtitle-1", width:'10%%',sortable:false},
          { text: "Total", value: "criterio", class: "subtitle-1" ,width:'5%'},
        ],
        infoTabela: [],
        escolhas1: ["Agrupamento","Município"],
        escolhas2: ["Gerais","Por Jogo"],
        escolhas3: ["Número de Jogos", "Número de Alunos", "Pontuação Média"],
        escolha1: null,
        escolha2: null,
        escolha3: null,
        jogos: [],
        jogosDisponiveis: {},
        jogo: null,
        loading: true,
        filtrar: ""
      };
    },
    props: {
      cod: {
        type: String,
        required: true,
      }
    },
    watch:{
      escolha3(){
        if(this.escolha2==="Gerais"){

          if(this.escolha1 === "Agrupamento"){

            this.infoTabela = Object.keys(this.statsPorEscola["gerais"]).map((key) => ({
              nome: key,
              ...this.statsPorEscola["gerais"][key]
            }))


          }else if(this.escolha1 === "Município"){

            this.infoTabela = Object.keys(this.statsPorLocalidade["gerais"]).map((key) => ({
              nome: key,
              ...this.statsPorLocalidade["gerais"][key]
            }))

          }

        }

        else if(this.escolha2==="Por Jogo"){
          if(this.escolha1 === "Agrupamento"){

            this.infoTabela = Object.keys(this.statsPorEscola[this.jogosDisponiveis[this.jogo]]).map((key) => ({
              nome: key,
              ...this.statsPorEscola[this.jogosDisponiveis[this.jogo]][key]
            }))

          }
          else if(this.escolha1 === "Município"){
            
            this.infoTabela = Object.keys(this.statsPorLocalidade[this.jogosDisponiveis[this.jogo]]).map((key) => ({
              nome: key,
              ...this.statsPorLocalidade[this.jogosDisponiveis[this.jogo]][key]
            }))

          }
        }

        switch(this.escolha3){
          case "Número de Jogos":
            this.infoTabela = this.infoTabela.sort((a,b)=>{
              if (a.totalJogos < b.totalJogos) {
                return 1;
              } else if (a.totalJogos > b.totalJogos) {
                return -1;
              } else {
                return 0;
              }
            })
            break
          case "Número de Alunos":
            this.infoTabela = this.infoTabela.sort((a,b)=>{
                if (a.totalAlunos < b.totalAlunos) {
                  return 1;
                } else if (a.totalAlunos > b.totalAlunos) {
                  return -1;
                } else {
                  return 0;
                }
            })
            break
          case "Pontuação Média":
            this.infoTabela = this.infoTabela.sort((a,b)=>{
                let avg_a = a.totalPontuacao / a.totalAlunos
                let avg_b = b.totalPontuacao / b.totalAlunos
                if (avg_a < avg_b) {
                  return 1;
                } else if (avg_a > avg_b) {
                  return -1;
                } else {
                  return 0;
                }
            })
            break
          default:
            this.infoTabela = []
            break
        }
        
        if(this.infoTabela.length > 0)
          this.infoTabela.forEach((item,index) =>{
            item.posicao = index + 1
          })

      },
      jogo(){
        if(this.escolha3 != null){
          if(this.escolha2==="Por Jogo"){
            if(this.escolha1 === "Agrupamento"){

              this.infoTabela = Object.keys(this.statsPorEscola[this.jogosDisponiveis[this.jogo]]).map((key) => ({
                nome: key,
                ...this.statsPorEscola[this.jogosDisponiveis[this.jogo]][key]
              }))

            }
            else if(this.escolha1 === "Município"){
              
              this.infoTabela = Object.keys(this.statsPorLocalidade[this.jogosDisponiveis[this.jogo]]).map((key) => ({
                nome: key,
                ...this.statsPorLocalidade[this.jogosDisponiveis[this.jogo]][key]
              }))

            }
          }
        }

        switch(this.escolha3){
          case "Número de Jogos":
            this.infoTabela = this.infoTabela.sort((a,b)=>{
              if (a.totalJogos < b.totalJogos) {
                return 1;
              } else if (a.totalJogos > b.totalJogos) {
                return -1;
              } else {
                return 0;
              }
            })
            break
          case "Total de Alunos":
            this.infoTabela = this.infoTabela.sort((a,b)=>{
                if (a.totalAlunos < b.totalAlunos) {
                  return 1;
                } else if (a.totalAlunos > b.totalAlunos) {
                  return -1;
                } else {
                  return 0;
                }
            })
            break
          case "Pontuação Média":
            this.infoTabela = this.infoTabela.sort((a,b)=>{
                let avg_a = a.totalPontuacao / a.totalAlunos
                let avg_b = b.totalPontuacao / b.totalAlunos
                if (avg_a < avg_b) {
                  return 1;
                } else if (avg_a > avg_b) {
                  return -1;
                } else {
                  return 0;
                }
            })
            break
        }
            
        this.infoTabela.forEach((item,index) =>{
          item.posicao = index + 1
        })

      }
    },
    created() {
      this.getCampeonatoInfo()
    },
    methods: {

      rowClass(item){
        if (parseInt(item.posicao) % 2 === 0)
          return "even-row"
        else
          return "odd-row"
      },

      getIcon(classificacao){
        switch(parseInt(classificacao)){
            case 1:
              return "mdi mdi-trophy icon-golden-trophy"
            case 2:
              return "mdi mdi-medal icon-gold-medal"
            case 3:
              return "mdi mdi-medal icon-silver-medal"
            default:
              if (parseInt(classificacao) < 11) {
                return "mdi mdi-star icon-silver-star";
              } else {
                return "";
              }
          }
      },

      async getCampeonatoInfo(){

        const infoCampeonato = await axios.get(host + "campeonatos/" + this.cod);
        const infoEscolas = await axios.get(host + "escolas");
        const infoJogos = await axios.get(host + 'jogos');


        if(infoCampeonato.data != null && infoEscolas.data != null && infoJogos.data != null){


          let info = infoCampeonato.data
          let escs = infoEscolas.data["escolas"]
          let js = infoJogos.data

          this.statsPorEscola["gerais"] = {}
          this.statsPorLocalidade["gerais"] = {}

          if(info != undefined)
            info.map(obj => {

              let esc = obj.escola
              let loc = escs.find(e => e.nome === obj.escola).localidade
              let j = js.find(e => e.jogo == obj.jogo).texto
              
              // Povoar Lista de Jogos
              if(!(this.jogos.includes(j))) this.jogos.push(j)
              if(!(Object.keys(this.jogosDisponiveis).includes(j))) this.$set(this.jogosDisponiveis, j, obj.jogo)

              // Povoar Lista de Escolas e Localidades
              if(!(this.escolas.includes(obj.escola))) this.escolas.push(esc)
              if(!(this.localidades.includes(escs[obj.escola]))) this.localidades.push(escs[obj.escola])

              // Estatisticas por Escola
              if(this.statsPorEscola[obj.jogo] && this.statsPorEscola[obj.jogo][esc]){

                  this.statsPorEscola[obj.jogo][esc]["totalAlunos"] += 1,
                  this.statsPorEscola[obj.jogo][esc]["totalPontuacao"] += obj.pontuacao,
                  this.statsPorEscola[obj.jogo][esc]["totalJogos"] += obj.njogos

              }else{

                if(!(this.statsPorEscola[obj.jogo]))
                  this.statsPorEscola[obj.jogo] = {}
                this.statsPorEscola[obj.jogo][esc] = {
                  "totalAlunos": 1,
                  "totalPontuacao": obj.pontuacao,
                  "totalJogos": obj.njogos
                }

              }
              if(this.statsPorEscola["gerais"][esc]){

                this.statsPorEscola["gerais"][esc]["totalAlunos"] += 1,
                this.statsPorEscola["gerais"][esc]["totalPontuacao"] += obj.pontuacao,
                this.statsPorEscola["gerais"][esc]["totalJogos"] += obj.njogos

              }else{

                this.statsPorEscola["gerais"][esc] = {
                  "totalAlunos": 1,
                  "totalPontuacao": obj.pontuacao,
                  "totalJogos": obj.njogos
                }

              }

              // Estatisticas por Municipio
              if(this.statsPorLocalidade[obj.jogo] && this.statsPorLocalidade[obj.jogo][loc]){

                this.statsPorLocalidade[obj.jogo][loc]["totalAlunos"] += 1,
                this.statsPorLocalidade[obj.jogo][loc]["totalPontuacao"] += obj.pontuacao,
                this.statsPorLocalidade[obj.jogo][loc]["totalJogos"] += obj.njogos

              }else{

                if(!(this.statsPorLocalidade[obj.jogo]))
                  this.statsPorLocalidade[obj.jogo] = {}
                this.statsPorLocalidade[obj.jogo][loc] = {
                  "totalAlunos": 1,
                  "totalPontuacao": obj.pontuacao,
                  "totalJogos": obj.njogos
                }

              }
              if(this.statsPorLocalidade["gerais"][loc]){

                this.statsPorLocalidade["gerais"][loc]["totalAlunos"] += 1,
                this.statsPorLocalidade["gerais"][loc]["totalPontuacao"] += obj.pontuacao,
                this.statsPorLocalidade["gerais"][loc]["totalJogos"] += obj.njogos

              }else{

                this.statsPorLocalidade["gerais"][loc] = {
                  "totalAlunos": 1,
                  "totalPontuacao": obj.pontuacao,
                  "totalJogos": obj.njogos
                }

              }

            })

          this.jogos.sort()
        }
        this.loading = false
      },

      
    }
} 

</script>

<style scoped>
  .icon-golden-trophy {
    color: gold;
  }

  .icon-gold-medal {
    color: #FFD700;
  }

  .icon-silver-medal {
    color: #C0C0C0;
  }

  .icon-silver-star {
    color: #C0C0C0;
  }

  .even-row {
    background-color: #F5F9F5 !important;
  }
  .odd-row {
    background-color: white !important;
  }

</style>