<template>
  <div class="grey lighten-3">

        <v-row justify="center">
            <v-dialog 
            content-class="elevation-0"
            :value="toggleLogin"
            @input="closeModal"
            scrollable
            max-width="800px"
            >
                <v-card width="50%" class="mx-auto mb-12">
                <v-card-title class="justify-center">
                    <h3 style="color:#009263">Login</h3>
                </v-card-title>
                <v-card-text>
                    <v-form>
                    <v-text-field
                        v-on:keyup.enter="submitForm"
                        color="#009263"
                        label="Utilizador"
                        prepend-icon="mdi-account-circle"
                        v-model="username"
                        :rules="rule"
                    />
                    <v-text-field
                        v-on:keyup.enter="submitForm"
                        color="#009263"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        prepend-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword"
                        v-model="password"
                        :rules="rule"
                    />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn block dark large color="#009263" class="mb-8" @click="submitForm"
                    >Login</v-btn
                    >
                </v-card-actions>
                <Footer class="grey lighten-3" />
                </v-card>
            </v-dialog>
        </v-row>
  </div>
</template>

<script>
const Swal = require("sweetalert2");
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer
  },
  data(){
    return {
      snackbar: false,
      username: "",
      password: "",
      showPassword: false,
      error: null,
      rule: [(val) => (val || "").length > 0 || "Campo obrigatório!"],
    }
  },
  props: {
    toggleLogin: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async submitForm() {
      try {
        if (!this.username || !this.password) {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#009263",
            title: "Não preencheu os dois campos!",
            width: 450,
          });
          return;
        }
        await this.$store.dispatch("auth", {
          username: this.username,
          password: this.password,
        });

        Swal.fire({
          icon: "success",
          confirmButtonColor: "#009263",
          title: "Login efetuado com sucesso.",
          width: 450,
        })
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#009263",
          title: "Credenciais erradas!",
          width: 450,
        });
      }
    },

    closeModal(){
      this.$emit("close")
    }
  },

  computed:{
  },

  mounted() {
  },
  watch: {

  },
};
</script>

<style scoped>
.v-dialog{
    box-shadow: none!important
}

</style>