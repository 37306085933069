<template>
  <div class="timer">
    <div class="timer__item">
      <v-icon
        class="timer__icon"
      >
      mdi mdi-clock
      </v-icon>
      <p class="timer__label" style="margin-right:10px;">Falta(m)</p>
      <p class="timer__value">{{ days }}</p>
      <p class="timer__label">dia(s)</p>
    </div>
    <div class="timer__item">
      <p class="timer__value">{{ hours }}</p>
      <p class="timer__label">hora(s)</p>
    </div>
    <div class="timer__item">
      <p class="timer__value">{{ minutes }}</p>
      <p class="timer__label">minuto(s)</p>
    </div>
    <div class="timer__item">
      <p class="timer__label">até ao {{ sentence }} do campeonato...</p>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: "Timer",
    data() {
      return {
        start: null,
        end: null,
        timeLeft: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }
      };
    },
    props: {
      endDate: {
        type: String,
        required: true
      },
      sentence:{
        type: String,
        required: true
      }
    },
    computed: {
        days() {
         return this.timeLeft.days;
        },
        hours() {
         return this.timeLeft.hours;
        },
        minutes() {
         return this.timeLeft.minutes;
        },
    },
    mounted() {
      this.start = new Date();
      this.end = new Date(this.endDate);
      this.calculateTimeLeft();
      this.interval = setInterval(() => {
        this.calculateTimeLeft();
      }, 60000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    methods: {
      calculateTimeLeft() {
        const timeLeft = this.getTimeLeft(this.start, this.end);
        this.timeLeft = timeLeft;
      },
      getTimeLeft(startDate, endDate) {
        const totalSeconds = (endDate - startDate) / 1000;
        const days = Math.floor(totalSeconds / (60 * 60 * 24));
        const hours = Math.floor((totalSeconds / (60 * 60)) % 24);
        const minutes = Math.floor((totalSeconds / 60) % 60);
        const seconds = Math.floor(totalSeconds % 60);
        return {
          days,
          hours,
          minutes,
          seconds
        };
      }
    }
  };
</script>
  
<style>
.timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.timer__item {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer__value {
  font-size: 1.8em;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.timer__icon {
  color: #009263 !important;
  margin-right: 5px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 4em !important;
}

.timer__label {
  font-size: 1.2em;
  margin: 0;
  display: flex;
  align-items: center;
}

/* Mobile devices (portrait) */
@media only screen and (max-width: 768px) {
  .timer {
    flex-direction: column;
    align-items: center;
  }
  
  .timer__item {
    margin-left: 2px;
    margin-bottom: 10px;
  }
  
  .timer__value {
    font-size: 1.5em;
    margin-right: 3px;
  }
  
  .timer__icon {
    font-size: 3em !important;
    margin-right: 2px;
    margin-bottom: 5px;
  }
  
  .timer__label {
    font-size: 1em;
    margin: 0;
  }
}


</style>