<template>
    <div class="grey lighten-3">
      <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
            <v-card-title class="justify-center">
              <h2 style="color:#009263">Jogos</h2>
            </v-card-title>
          <div>
            <!-- Tabela -->
            <v-container v-if="loading">
              <center>
                <v-img
                  :src="require('@/assets/loading.gif')"
                  width="150px"
                  heigth="150px"
                >
                </v-img>
              </center>
            </v-container>
            <v-container v-else>
                <v-data-table
                    no-data-text="Não existem campeonatos neste momento."
                    hide-default-footer
                    :headers="headers" 
                    :items="campeonatos"
                    item-key="campeonatoid"
                    class="elevation-1"
                    color="#009263"
                    >
                    

                    <template v-slot:[`item.campeonato`]="{ item }">
                        Edição {{ formatDate(item.datai) }}
                    </template>
                    <template v-slot:[`item.acoes`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="showModal = true, codCampeonato=item.id"
                            >
                                <v-icon color="#009263">
                                mdi-pencil
                                </v-icon>
                            </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        
                    </template>
                </v-data-table>
                <EditarCampeonato
                  :showModal="showModal"
                  :codCampeonato="codCampeonato"
                  @close="showModal = false"
                />  
            </v-container>
          </div>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
    </div>
  </template>
  
  <script>
  const axios = require("axios");
  const host = require("@/config/hosts").hostAPI;
  const moment = require("moment")
  import EditarCampeonato from "@/components/EditarCampeonato.vue";
  
  export default {
    components:{
      EditarCampeonato,
    },
    props: {
  
    },
    data() {
      return {
        loading: false,
        show:false,
        codCampeonato: '',
        campeonatos: [],
        headers: [
          { text: "Campeonato", value: "campeonato", class: "title",style: "text-align: center", width:'100%'},
          { text: "Ações", value: "acoes", class: "subtitle-1"}
        ],
        showModal: false,
        
      };
    },
    methods: {

      async getProximosCampeonatos(){
        const data = await axios.get(host + "campeonatos-ids/proximoInativo")
        if(data!=null){
          this.campeonatos = data.data["prox"]
          this.loading=false;
        }
      },

      formatDate(date){
        return moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY') : 'Indisponível'
      },
  

    },
    computed: {
  
    },
    mounted() {
      this.getProximosCampeonatos()
    }
  };
  </script>
  
  <style>
  .swal2-textarea{
    border-color: #009263 !important
  }
  table th + th { border-left:1px solid #dddddd; }
  table td + td { border-left:1px solid #dddddd; }
  </style>
  