<template>
    <div class="grey lighten-3">
      <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
            <v-card-title class="justify-center">
              <h2 style="color:#009263">Certificados</h2>
            </v-card-title>
            <div v-if="isLoggedIn">
                <v-container v-if="loading">
                            <center>
                                <v-img
                                :src="require('@/assets/loading.gif')"
                                width="150px"
                                heigth="150px"
                                >
                                </v-img>
                            </center>
                </v-container>
                <div v-else>
                    <div>
                        <CertificadosAdmin
                            v-if="premium == 5"
                        />
                        <CertificadosProfessor
                            v-if="premium == 1"
                        />
                        <CertificadosAluno
                            v-if="premium == 0"
                        />
                    </div> 

                </div>
            </div>
            <div v-else>
                <NoAuth @login="$emit('login')"/>
            </div>
            </v-card>
        </v-container>
      </v-main>
    </v-app>
    </div>
</template>

<script>
import NoAuth from '@/components/NoAuth.vue';
import CertificadosAluno from '@/components/CertificadosAluno.vue';
import CertificadosAdmin from '@/components/CertificadosAdmin.vue';
import CertificadosProfessor from '@/components/CertificadosProfessor.vue';


export default {
    components: {
        NoAuth,
        CertificadosAdmin,
        CertificadosAluno,
        CertificadosProfessor
    },
    data(){
        return {
            loading: false,
            show: false,
            userID: null,
            premium: null,
        }
    },
    methods: {
        
    },
    created: async function() {
        this.userID = this.$store.getters.getUserId;
        this.premium = this.$store.getters.getPremiumType;
    },
    mounted(){

    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        }
    }
}

</script>

<style scoped>

.file-list-container {
  border: 1px solid #ccc;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.file-list {
    height: auto;
    overflow-y: scroll;
    width: 98%; 
    margin: auto; 
}
.file-row {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.last-row {
  border-bottom: none;
}
.file-name {
    flex: 1;
    margin-right: 10px;
}

.download-button {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
</style>