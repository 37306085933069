<template>
  <div>
    <div v-if="getUserType() == 'professor' && temTurmas">
      <v-combobox
            id="escolha"
            label="Filtrar por"
            prepend-icon="mdi-magnify"
            v-model="escolha"
            style="width: 200px; margin-left: auto;"
            color="#009263"
            :items="escolhas"
            @change="campeonato=[]; turma=null;jogo='';ano='';"
      >
      </v-combobox>
      <v-combobox
            v-show="escolha === 'Turma'"
            id="turma"
            label="Turma"
            prepend-icon="mdi-google-classroom"
            v-model="turma"
            style="width: 200px; margin-left: auto;"
            color="#009263"
            :items="turmasProfessor"
            @change="showCampeonato=true; campeonato=[];loading=true; getCampeonato(); "
      ></v-combobox>
      <div v-if="escolha === 'Jogo'">
        <v-combobox
            id="jogo"
            label="Jogo"
            prepend-icon="mdi-gamepad-variant"
            v-model="jogo"
            style="width: 200px; margin-left: auto;"
            color="#009263"
            :items="listaJogos"
            @change="campeonato=[];ano=''; showAno=true; showCampeonato=true;loading=true; getCampeonato(); "
      ></v-combobox>
      <v-combobox
            id="ano"
            label="Ano"
            v-show="showAno && has_targets"
            prepend-icon="mdi-counter"
            v-model="ano"
            style="width: 200px; margin-left: auto;"
            color="#009263"
            :items="anosDisponiveis"
            @change="campeonato=[];loading=true; getCampeonato()"
        ></v-combobox>
      </div>
    </div>
    <div v-else>
      <v-combobox
            id="jogo"
            label="Jogo"
            prepend-icon="mdi-gamepad-variant"
            v-model="jogo"
            style="width: 200px; margin-left: auto;"
            color="#009263"
            :items="listaJogos"
            @change="ano=''; showAno=true; showCampeonato=true;loading=true; getCampeonato(); "
      ></v-combobox>
      <v-combobox
            id="ano"
            label="Ano"
            v-show="showAno && has_targets"
            prepend-icon="mdi-counter"
            v-model="ano"
            style="width: 200px; margin-left: auto;"
            color="#009263"
            :items="anosDisponiveis"
            @change="loading=true; getCampeonato()"
        ></v-combobox>
    </div>
      <div v-if="existeRanking">
        <v-text-field
                    v-model="filtrar"
                    label="Filtrar"
                    prepend-icon="mdi-magnify"
                    color="#009263"
                    single-line
        ></v-text-field>
        <v-data-table
            v-if="existeRanking"
            no-data-text="Não existe ranking neste momento."
            :footer-props="{
                'items-per-page-text': 'Mostrar',
                'items-per-page-options': [15, 25, 35, -1],
                'items-per-page-all-text': 'Todos',
            }"
            dense
            :items-per-page=15
            :headers="headers" 
            :items="campeonato"
            item-key="jogoid"
            class="elevation-1"
            color="#009263"
            :search="filtrar"
            > 
            <!-- <template v-slot:[`item.classificacao`]="{ item }">
                <div class="justify-center">
                <v-icon>{{ getIcon(item.classificacao) }}</v-icon>
                {{ item.classificacao }}
                </div>
            </template>
            <template v-slot:[`item.nome`]="{ item }">
                {{ item.nome }}
            </template>
            <template v-slot:[`item.pontos`]="{ item }">
                {{ item.pontuacao }}
            </template>
            <template v-slot:[`item.escola`]="{ item }">
                {{ item.escola }}
            </template>
            <template v-slot:[`item.ano`]="{ item }">
                {{ item.ano[0] }}
            </template>
            <template v-slot:[`item.numjogos`]="{ item }">
                {{ item.njogos }}
            </template> -->
            <template v-slot:item="{ item, index }">
                <tr :class="index % 2 === 0 ? 'even-row' : 'odd-row'">
                    <td>
                        <v-icon>{{ getIcon(item.classificacao) }}</v-icon>
                        {{ item.classificacao }}
                    </td>
                    <td>{{ item.nome }}</td>
                    <td>{{ item.pontuacao }}</td>
                    <td>{{ item.escola.replace(/Agrupamento de Escolas/g, "AE") }}</td>
                    <td>{{ item.ano[0] }}</td>
                    <td>{{ item.njogos }}</td>
                </tr>
            </template>
        </v-data-table>
        <v-row 
        v-if="getUserType() == 'professor' && temTurmas && turma != null"
        class="mt-4">
          <v-col cols="12" md="4" offset-md="8" class="text-md-right">
            <v-btn  
              @click="downloadClassificacao()"
              color="#009263"
              class="ml-n4 white--text mr-4"
              >
            Descarregar
            </v-btn>
          </v-col>
        </v-row>
    </div>
    <div v-else-if="!existeRanking && showCampeonato">
      <div v-if="loading">
        <v-container>
              <center>
                <v-img
                  :src="require('@/assets/loading.gif')"
                  width="150px"
                  heigth="150px"
                >
                </v-img>
              </center>
          </v-container>
      </div>
      <div v-else>
        <v-container align="center" justify="center">
        <v-img
        :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
        >
            <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
                <v-progress-circular
                indeterminate
                color="grey lighten-5"
                ></v-progress-circular>
            </v-row>
            </template>
        </v-img>
        <h2 style="text-align: center">De momento não existem participações no campeonato.</h2>
        </v-container>
      </div>
  </div> 
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
import Swal from "sweetalert2";
const jsPDF = require('jspdf');
import 'jspdf-autotable'
import imageCampeonatos from "@/assets/campeonatos.jpeg"

export default {
    data() {
      return {
        showAno: false,
        showCampeonato: false,
        existeRanking: false,
        edicao: null,
        campeonato: [],
        ano: "",
        jogo: '',
        anosDisponiveis: [],
        jogoAnos: {},
        jogosDisponiveis: {},
        listaJogos: [],
        headers: [
          { text: "", value: "classificacao", class: "subtitle-1" ,width:'7%', sortable:false},
          { text: "Jogador", value: "nome", class: "subtitle-1", width:'10%%'},
          { text: "Pontos", value: "pontos", class: "subtitle-1" ,width:'5%'},
          { text: "Agrupamento de Escolas", value: "escola", class: "subtitle-1" ,width:'40%'},
          { text: "Ano", value: "ano", class: "subtitle-1", width:'5%'},
          { text: "#", value:"numjogos", class: "subtitle-1", width:"5%"}
        ],
        filtrar: "",

        escolha: null,
        escolhas: ["Turma","Jogo"],
        temTurmas: false,
        turmasProfessor: [],
        turma: null,

        has_targets: null,
        loading: true,
      };
    },
    props: {
      cod: {
        type: String,
        required: true,
      },
    },
    created() {
      this.setFiltros()
      if(this.getUserType() == "professor") this.getTurmasProf()
    },
    methods: {

      async downloadClassificacao(){
        const infoCampeonato = await axios.get(host + "campeonatos-ids/" + this.cod)
        
        var dataC = infoCampeonato.data[this.cod][0]

        const infoRankingCampeonato = await axios.get(host + "campeonatos/" + this.cod +  "?jogo=" + this.campeonato[0].jogo)
        var totalAlunosJogo = infoRankingCampeonato.data.length

        const infoJogo = await axios.get(host + "jogos/" + this.campeonato[0].jogo)
        var nomeJogo = infoJogo.data.texto

        const targetsCampeonato = await axios.get(host + "target/" + this.cod)
        var jogoTarget = targetsCampeonato.data[this.cod].find(e => e.jogo == this.campeonato[0].jogo)

        const doc = new jsPDF.jsPDF('portrait');

        doc.setFontSize(36)
        doc.setFont('helvetica', 'bold');
        doc.setTextColor("#009263")
        doc.text("Classificação",(doc.internal.pageSize.width) / 2 - 40, 20)

        doc.addImage(imageCampeonatos,"JPEG",(doc.internal.pageSize.width) / 2 + 70, 5, 30, 30)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12)
        doc.setTextColor("black")
        doc.text("Campeonato: " + dataC.descricao,(doc.internal.pageSize.width) / 2 - 80, 40)
        doc.text("Turma: " + this.turma,(doc.internal.pageSize.width) / 2 - 80, 50)
        if(jogoTarget.tipo != "")
          doc.text("Jogo: " + nomeJogo + " - " + jogoTarget.tipo,(doc.internal.pageSize.width) / 2 + 25, 50)
        else
          doc.text("Jogo: " + nomeJogo,(doc.internal.pageSize.width) / 2 + 50, 50)

        const columns = ["Pos.","Jogador","Pontos","Jogos"]
        const rows = this.campeonato.map(obj => [obj.classificacao,obj.nome,obj.pontuacao,obj.njogos])

        doc.autoTable({
          startY: 60,
          styles : { halign : 'center'},
          head: [columns],
          body: rows,
          theme: 'grid',
          headStyles : {fillColor : [0, 146, 99]}
        });

        doc.text("Total Participantes: " + totalAlunosJogo,(doc.internal.pageSize.width) / 2 + 40, doc.autoTable.previous.finalY + 10)


        const pdfContent = doc.output('bloburl');

        const blob = await fetch(pdfContent).then(res => res.blob());
        
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'Classificacao.pdf';

        // Download
        a.click();

      },

      async setFiltros(){
        let response = await axios.get(host + "campeonatos-ids/" + this.cod)
        let data = response.data
        if(data != undefined){
          let t = data[this.cod][0].has_target
          if(t==0){
            this.has_targets = false
            this.getJogosListaAntigo()
          } 
          else if(t==1){
            this.has_targets = true
            this.getJogosListaNovo()
          } 
          else console.log("error on getting targets")
        }
      },

      async getJogosListaNovo(){
          let response = await axios.get(host + "target/" + this.cod)
          
          if(response.data != {}){
            let campeonatoTargets = response.data[this.cod]
            
            let jogos = campeonatoTargets.map(item => item.jogo);

            let jogosData = await axios.get(host + 'jogos');
            var jogoDB = jogosData.data;

            jogoDB = jogoDB.map(obj => {
              const splitJogoAno = obj.texto.split('(');
              const jogo = splitJogoAno[0].trim();
              const anos = splitJogoAno[1].split('.')[0].split('/');

              return { [jogo]: {[anos]: obj.jogo }};
            });

          
          let comuns = jogoDB.filter(obj => {
            const value = Object.values(obj)[0];
            const id = Object.values(value)[0];
            return jogos.includes(id);
          });
          
          comuns.forEach((item) => {
            
            if(this.jogosDisponiveis[Object.keys(item)[0]]){
              this.jogosDisponiveis[Object.keys(item)[0]].push(Object.values(item)[0])
            }
            else{
              this.jogosDisponiveis[Object.keys(item)[0]] = [Object.values(item)[0]]
            }
            if(this.jogoAnos[Object.keys(item)[0]]){
              
              this.jogoAnos[Object.keys(item)[0]] = this.jogoAnos[Object.keys(item)[0]].concat(Object.keys(Object.values(item)[0])[0])
            }else{
              
              this.jogoAnos[Object.keys(item)[0]] = [Object.keys(Object.values(item)[0])[0]]
            }
            
          });

          this.listaJogos = comuns.map(o => Object.keys(o)[0])
        }
          
      },
      
      async getJogosListaAntigo(){

        // Ir buscar jogos usados no campeonato
        let response = await axios.get(host + "campeonatos/" + this.cod + "/jogos");
        let jogosCampeonato = response.data
        

        let jogos = jogosCampeonato.map(item => item.jogoid);


        var jogosData = await axios.get(host + 'jogos');
        var jogoDB = jogosData.data;
        jogoDB = jogoDB.map(obj => {
          const splitJogoAno = obj.texto.split('(');
          let anos = splitJogoAno[1].split('.')[0];
          var arrayAnos = anos.split('/')
          this.jogoAnos[obj.texto] = arrayAnos
          return { texto: obj.texto, jogo: obj.jogo };
        });
        
        let comuns = jogoDB.filter((b) => jogos.includes(b.jogo));

        comuns.forEach((item) => {
          this.$set(this.jogosDisponiveis, item.texto, item.jogo);
        });
        this.listaJogos = comuns.map(o => o.texto)

      },

      async getCampeonato(){

        let jogoAlvo = null
        if(this.jogo != ""){
          if(!this.has_targets)
            jogoAlvo = this.jogosDisponiveis[this.jogo]
          else
            jogoAlvo = Object.values(Object.values(this.jogosDisponiveis[this.jogo])[0])[0]
        }

        var response = null

        if(this.getUserType() == "professor" && this.escolha == "Turma"){
          if(this.turma != null){
            response = await axios.get(host + "campeonatos/" + this.cod)
            let resultadoTurma = response.data.find(e => e.ano === this.turma )
            console.log(resultadoTurma)
            response = await axios.get(host + "campeonatos/" + this.cod + "?jogo=" + resultadoTurma.jogo)
            this.campeonato = response.data
          }else{
            Swal.fire({
              icon: "warning",
              confirmButtonColor: "#009263",
              title: "Escolha uma turma válida!",
            });
          }
        }else{

          if(jogoAlvo != undefined){
            if(this.ano != ""){
              if(this.has_targets)
                jogoAlvo = Object.values(Object.values(this.jogosDisponiveis[this.jogo]).find(e => Object.keys(e)[0] == this.ano))[0]
              
              response = await axios.get(host + "campeonatos/" + this.cod +  "?jogo=" + jogoAlvo + "&ano=" + this.ano);
              this.campeonato = response.data
            }
            else{
              response = await axios.get(host + "campeonatos/" + this.cod +  "?jogo=" + jogoAlvo);
              this.campeonato = response.data
              
              this.anosDisponiveis = this.jogoAnos[this.jogo].sort()
              
            }
          }else{
            
            if(this.has_targets)
              response = await axios.get(host + "campeonatos/" + this.cod + "?jogo=" + Object.values(Object.keys(this.jogosDisponiveis)[0])[0])
            else
              response = await axios.get(host + "campeonatos/" + this.cod + "?jogo=" + this.jogosDisponiveis[0]); 
            this.campeonato = response.data

            
            this.anosDisponiveis = this.jogoAnos[this.jogo].sort()
            
          }
        }
        
        if(this.campeonato == undefined) this.existeRanking = false
        else {
          this.existeRanking = true

          this.campeonato = this.campeonato.sort((a, b) => {
            if (a.pontuacao === b.pontuacao)
              return a.njogos - b.njogos
            return b.pontuacao - a.pontuacao
          });

         
          for(let i=0; i < this.campeonato.length - 1; i++){

            if(this.campeonato[i].pontuacao == this.campeonato[i+1].pontuacao){
                  
              this.campeonato[i+1].classificacao = parseInt(this.campeonato[i].classificacao)

            }
            else{

              this.campeonato[i+1].classificacao = parseInt(this.campeonato[i].classificacao) + 1

            }
            
          }
        }

        if(this.getUserType() == "professor" && this.escolha == "Turma"){
          this.campeonato = this.campeonato.filter(s => s.ano === this.turma && s.codprofessor === this.$store.getters.getUserId)
        }

      },

      getIcon(classificacao){
        switch(parseInt(classificacao)){
            case 1:
              return "mdi mdi-trophy icon-golden-trophy"
            case 2:
              return "mdi mdi-medal icon-gold-medal"
            case 3:
              return "mdi mdi-medal icon-silver-medal"
            default:
              if (parseInt(classificacao) < 11) {
                return "mdi mdi-star icon-silver-star";
              } else {
                return "";
              }
          }
      },

      getUserType(){
        return this.$store.getters.getUserType;
      },

      async getTurmasProf(){
          
          const data = await axios.get(host + "turmas/" + this.$store.getters.getUserId + "?campeonato=" + this.cod)
          if(data.data != undefined)
            if(Object.keys(data.data).length > 0 ){
              let turmas = data.data["turmas"]
              turmas.map( t => {
                this.turmasProfessor.push(t.turma)
              })
              this.temTurmas = true
              
            }

      },
    },
    computed: {
    },
  

  };
</script>

<style scoped>
  .icon-golden-trophy {
    color: gold;
  }

  .icon-gold-medal {
    color: #FFD700;
  }

  .icon-silver-medal {
    color: #C0C0C0;
  }

  .icon-silver-star {
    color: #C0C0C0;
  }

  .even-row {
    background-color: #F5F9F5 !important;
  }
  .odd-row {
    background-color: white !important;
  }
</style>