<template>
    <div>
     <!-- Ajuda -->
    <center>
        <v-btn v-if="!show" text @click="show = !show"
        ><span>Mostrar Ajuda</span
        ><v-icon color="#009263"> mdi-help-circle </v-icon>
        </v-btn>
        <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
    </center>
    <v-slide-y-transition>
        <v-card
        v-show="show"
        class="elevation-6 pa-3"
        style="border: 2px solid green !important;"
        color="grey lighten-3"
        >
        <v-row class="justify-center">
        <v-col cols="12">
            <span>
            1. Nesta página tem acesso aos certificados de participação.
            </span>
        </v-col>
        </v-row>
        </v-card>
    </v-slide-y-transition>
    <div v-if="temCampeonatosParticipados">
        <v-list>
              <v-list-group
                v-for="key in Object.keys(campeonatos)"
                :key="key"
                color="#009263"
                class="campeonato-list-container"
                @click="onKeyChange"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <h2 class="campeonato-name" style="color:#009263">
                      <v-icon color="#009263">mdi mdi-trophy</v-icon>
                      {{ key }}
                    </h2>
                  </v-list-item-content>
                </template>
                <v-combobox
                    id="turma"
                    label="Turma"
                    prepend-icon="mdi-google-classroom"
                    v-model="turma"
                    style="width: 200px; margin-left: auto;"
                    color="#009263"
                    :items="getTurmasProfessor(key)"
                ></v-combobox>
                <div 
                    v-if="turma != null"
                    class="campeonato-list">
                    <div 
                        class="campeonato-row" 
                        v-for="(entry,index) in entries[key]" 
                        :key="index"
                        :class="{ 'last-row': index === entries.length - 1 }"
                        >
                            <span class="campeonato-name">{{entry.aluno}}</span>
                            <v-btn
                                class="download-button"
                                color="#009263"
                                @click="downloadCertificado(entry)"
                            >
                            <span style="color:white">Descarregar</span>
                            </v-btn>
                    </div>
                </div>
              </v-list-group>
        </v-list>
    </div>
    <div v-else>
        <v-container align="center" justify="center">
            <v-img
            :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
            >
            </v-img>
            <h2 style="text-align: center">De momento não tem certificados disponíveis.</h2>
        </v-container>
    </div> 
</div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require('moment');
const jsPDF = require('jspdf');
import Swal from "sweetalert2";

export default {
    watch: {
        turma(){
            this.entries = []
            this.setEntriesByTurma()
        },
    },
    data(){
        return{
            userID: null,
            temCampeonatosParticipados: false,
            campeonatos: {},

            turma: null,
            turmasProfessor: {},
            entries: {},
            campeonatoOpen: false,
            show: false
        }
    },
    methods: {

        onKeyChange(){
            if(!this.campeonatoOpen && this.turma == null){
                this.campeonatoOpen = true
            }else if(!this.campeonatoOpen && this.turma != null){
                this.turma = null
                this.campeonatoOpen = true
            }else if(this.campeonatoOpen && this.turma != null){
                this.turma = null
            }else{
                this.campeonatoOpen = true
            }
        },

        setEntriesByTurma(){
            Object.entries(this.campeonatos).forEach(k => {
                    k[1].forEach(e => {
                        if(e.turma === this.turma)
                            if(this.entries[k[0]])
                                this.entries[k[0]].push(e)
                            else
                                this.entries[k[0]] = [e]
                    })
                })
        },

        getTurmasProfessor(campeonato){
            return this.turmasProfessor[campeonato]
        },

        async getCampeonatosParticipados(){
            const dataCampeonatos = await axios.get(host + "campeonatos/participou/professor/" + this.userID);
            if(Object.keys(dataCampeonatos.data).length > 0){

                this.campeonatos = dataCampeonatos.data
                Object.entries(this.campeonatos).forEach(k => {
                    k[1].forEach(e => {
                        if(this.turmasProfessor[k[0]]){
                            if(!(Object.values(this.turmasProfessor[k[0]]).includes(e.turma)))
                                this.turmasProfessor[k[0]] = Object.values(this.turmasProfessor[k[0]]).concat(e.turma)
                        }else{
                            this.turmasProfessor[k[0]] = [e.turma]
                        }
                    })
                })
                this.temCampeonatosParticipados = true
            }
        },
        
        async downloadCertificado(item){
            
            const infoCertificado = await axios.get(host + "certificados-infos/" + item.campeonato);
            const infoAluno = await axios.get(host + "alunos/" + item.codaluno)
            const dataCampeonatos = await axios.get(host + "campeonatos/" + item.campeonato);
            const campeonatoTargets = await axios.get(host + "target/" + item.campeonato)
            
            var dataC = dataCampeonatos.data
            var dataA = infoAluno.data
            var dataT = campeonatoTargets.data[item.campeonato]
            
            var resAlunoCampeonato = dataC.find(e => e.nome == dataA.nome)

            const infoJogo = await axios.get(host + "jogos/" + resAlunoCampeonato.jogo)
            var dataJ = infoJogo.data
            const resultadosCampeonato = await axios.get(host + "campeonatos/" + item.campeonato + "?jogo=" + resAlunoCampeonato.jogo)
            dataC = resultadosCampeonato.data
            var jogoCampeonato = dataT.find(e => e.jogo == resAlunoCampeonato.jogo)
            if(dataC.length > 0){
                dataC = dataC.sort((a, b) => {
                if (a.pontuacao == b.pontuacao)
                    return a.njogos - b.njogos
                    return b.pontuacao - a.pontuacao
                });

                for(let i=0; i < dataC.length - 1; i++){

                    if(dataC[i].pontuacao == dataC[i+1].pontuacao){
                        
                    dataC[i+1].classificacao = parseInt(dataC[i].classificacao)
                    }
                    else{

                    dataC[i+1].classificacao = parseInt(dataC[i].classificacao) + 1

                    }
                }
            }
            resAlunoCampeonato = dataC.find(e => e.nome == dataA.nome)
            let info = infoCertificado.data
            if(info != undefined){
                if(info.length == 1){
                    const id = info[0].id
                    const frase = info[0].frase
                    const bannersInfo = await axios.get(host + "certificados-infos/banners/" + id)
                    let bannersData = bannersInfo.data["urls"]
                    if(bannersData.length == 2){

                        const banner1url = bannersData.find(e => e.banner === "banner1").url
                        const banner2url = bannersData.find(e => e.banner === "banner2").url

                        let anoJogo = dataJ.texto.split('(')[1].split('.')[0]

                        const di = new Date(item.di);
                        const df = new Date(item.df);

                        const diDay = di.getDate();
                        const dfDay = df.getDate();
                        const diMonth = di.toLocaleString('pt-PT', { month: 'long' });
                        const dfMonth = df.toLocaleString('pt-PT', { month: 'long' });
                        const year = di.getFullYear();

                        let dateString;

                        if (diMonth !== dfMonth) {
                            dateString = `${diDay} de ${diMonth} a ${dfDay} de ${dfMonth} de ${year}`;
                        } else if ((df - di) / (1000 * 60 * 60 * 24) >= 2) {
                            dateString = `${diDay} a ${dfDay} de ${diMonth} de ${year}`;
                        } else {
                            if(diDay == dfDay)
                                dateString = `${dfDay} de ${dfMonth} de ${year}`
                            else
                                dateString = `${diDay} e ${dfDay} de ${diMonth} de ${year}`;
                        }

                        var completeText="";
                        if(resAlunoCampeonato.classificacao == "1"){
                            if(jogoCampeonato.tipo != ""){
                                completeText = "Certifica-se que " + dataA.nome + " foi a(o) campeã(o) na " + frase + " - "  + dataJ.descricao +
                                " - " + jogoCampeonato.tipo + ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString + "."
                            }else{
                                completeText = "Certifica-se que " + dataA.nome + " foi a(o) campeã(o) na " + frase + " - "  + dataJ.descricao +
                                ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString + "."
                            }
                        }else{
                            if(jogoCampeonato.tipo != ""){
                                completeText = "Certifica-se que " + dataA.nome + " participou na " + frase + " - "  + dataJ.descricao +
                                " - " + jogoCampeonato.tipo + ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString
                                + ", tendo ficado(a) colocado(a) na " + resAlunoCampeonato.classificacao + ".ª posição."     
                            }else{
                                completeText = "Certifica-se que " + dataA.nome + " participou na " + frase + " - "  + dataJ.descricao +
                                ", para alunos do " + anoJogo + ".º ano de escolaridade, realizado no(s) dia(s) " + dateString
                                + ", tendo ficado(a) colocado(a) na " + resAlunoCampeonato.classificacao + ".ª posição."     
                            }
                        }
                        let finalDate = "Braga, " + dfDay + " de " + dfMonth + " de " + year + "."

                        const doc = new jsPDF.jsPDF('landscape');
                        
                        doc.addImage(host + banner1url.slice(1), 'JPEG', 5, 5, doc.internal.pageSize.width - 10, 30);
                        
                        
                        doc.setFontSize(18);
                        doc.setFont('helvetica', 'normal');
                        
                        
                        const textWidth = doc.internal.pageSize.width * 0.8;
                        const textHeight = doc.internal.getFontSize() * 0.8;
                        const lines = doc.splitTextToSize(completeText, textWidth);
                        let textY = (doc.internal.pageSize.height - (lines.length * textHeight)) / 2 - 30;
                        doc.text(completeText,(doc.internal.pageSize.width - textWidth) / 2, textY,{
                                maxWidth: textWidth,
                                lineHeightFactor: 2,
                                align: "justify"
                            })
                        
                        for (let i = 0; i < lines.length; i++) {
                            textY += textHeight
                        }


                        doc.text(finalDate,(doc.internal.pageSize.width) / 2 - 40, textY + 1)

                        doc.addImage(host + banner2url.slice(1), 'JPEG', 0, doc.internal.pageSize.height - 100, doc.internal.pageSize.width, 100);

                                                
                        doc.setDrawColor("#009263")
                        doc.setLineWidth(2)
                        doc.rect(0, 0, doc.internal.pageSize.width , doc.internal.pageSize.height, 'S');
                        const pdfContent = doc.output('bloburl');

                        const blob = await fetch(pdfContent).then(res => res.blob());
                        
                        const url = URL.createObjectURL(blob);

                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'Certificado.pdf';

                        // Download
                        a.click();
                    }
                }
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro.',
                    confirmButtonColor: '#009263'
                })
            }
        },

        getEdicao(date){
          let data = moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY') : 'Indisponível';
          return ("Edição " + data)
        },

    },
    async created(){
        this.userID = this.$store.getters.getUserId;
    },
    mounted(){
        this.getCampeonatosParticipados();
    }

}

</script>

<style scoped>
.campeonato-list-container {
   border: 1px solid #ccc;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
   overflow: hidden;
 }
 .campeonato-list {
     height: auto;
     overflow-y: scroll;
     width: 98%; 
     margin: auto; 
 }
 .campeonato-row {
     display: flex;
     align-items: center;
     padding: 10px;
     border-top: 1px solid #ccc;
     border-bottom: 1px solid #ccc;
 }

 .last-row {
   border-bottom: none;
 }
 .campeonato-name {
     flex: 1;
     margin-right: 10px;
     color: black;
 }

 .download-button {
   background-color: #2196f3;
   color: white;
   border: none;
   padding: 5px 10px;
   border-radius: 5px;
   cursor: pointer;
   margin-right: auto;
 }

</style>