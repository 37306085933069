<template>
    <div class="grey lighten-3">
      <v-app id="inspire">
      <v-main class="grey lighten-3">
        <v-container>
          <v-card class="pa-5">
            <v-card-title class="justify-center">
              <h2 class="title-campeonato" style="color:#009263">{{"Campeonato Ativo"}}</h2>
            </v-card-title>
            <v-container v-if="loading">
                        <center>
                            <v-img
                            :src="require('@/assets/loading.gif')"
                            width="150px"
                            heigth="150px"
                            >
                            </v-img>
                        </center>
            </v-container>
            
            <div v-else>
              <div v-if="existeCampeonato">
              <!-- Ajuda -->
              <center>
                      <v-btn v-if="!show" text @click="show = !show"
                          ><span>Mostrar Ajuda</span
                          ><v-icon color="#009263"> mdi-help-circle </v-icon>
                      </v-btn>
                      <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
              </center>
              <v-slide-y-transition>
                      <v-card
                          v-show="show"
                          class="elevation-6 pa-3"
                          style="border: 2px solid green !important;"
                          color="grey lighten-3"
                      >
                          <v-row class="justify-center">
                              <v-col cols="12">
                                  <span>
                                  1. Esta página contém o ranking do campeonato que decorre atualmente.
                                  </span>
                              </v-col>
                              <v-col cols="12">
                                  <span>
                                  2. Poderá encontrar informações sobre o mesmo no botão "Mostrar Informações".
                                  </span>
                              </v-col>
                              <v-col cols="12">
                                  <span>
                                  3. Poderá também consultar estatísticas gerais sobre o campeonato ativo no botão "Estatísticas".
                                  </span>
                              </v-col>
                          </v-row>
                      </v-card>
              </v-slide-y-transition>
              <v-combobox
                    v-show="multipleCampeonatos"
                    id="edicao"
                    label="Campeonato"
                    prepend-icon="mdi-magnify"
                    v-model="edicao"
                    style="width: 300px; margin-left: auto; margin-right: auto;"
                    color="#009263"
                    :items="getCampeonatos()"
                    @change="setCampeonato"
                    >
              </v-combobox>
                <div v-if="edicao != null || !multipleCampeonatos">
                  <v-row class="info-box">
                    <v-col class="info-col">
                      <span><b>Alunos: </b>  {{ this.statsGerais.totalAlunos }}</span>
                    </v-col>
                    <v-col class="info-col">
                      <span><b>Jogos: </b>  {{ this.statsGerais.totalJogos }}</span>
                    </v-col>
                    <v-col class="info-col">
                      <span><b>Agrupamentos: </b>  {{ this.statsGerais.totalAgrupamentos }}</span>
                    </v-col>
                    <v-col class="info-col">
                      <span><b>Municípios: </b> {{ this.statsGerais.totalMunicipios }}</span>
                    </v-col>
                    <v-col class="info-col">
                      <span><b>Rácio: </b> {{ (this.statsGerais.totalJogos / this.statsGerais.totalAlunos).toFixed(2) }}</span>
                    </v-col>
                  </v-row>

                  <!-- Informacoes -->
                  <center>
                      <v-btn v-if="!showInfo" text @click="showInfo = !showInfo"
                          ><span>Mostrar Informações</span
                          ><v-icon color="#009263"> mdi-information-outline </v-icon>
                      </v-btn>
                      <v-btn v-else text @click="showInfo = !showInfo">Esconder Informações</v-btn>
                  </center>
                  <v-slide-y-transition>
                      <v-card
                          v-show="showInfo"
                          class="elevation-6 pa-3"
                          style="border: 2px solid green !important;"
                          color="grey lighten-3"
                      >
                          <v-row class="justify-center">
                              <v-col cols="12">
                                  <span>
                                  1. {{getFrase1()}}
                                  </span>
                              </v-col>
                              <v-col cols="12">
                                  <span>
                                  2. {{getFrase2()}}
                                  </span>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-slide-y-transition>
                  <br/>
                  
                  <div v-if="existeRanking">
                    <v-row justify="center" align="center">
                        <v-btn
                        class="mx-4 mb-4"
                        color="#009263"
                        @click="showRanking= false, showStats = !showStats"
                        >
                        <span style="color:white"> Estatísticas </span>
                      </v-btn>
                      <v-btn 
                        class="mx-4 mb-4"
                        color="#009263"
                        @click="showStats= false, showRanking = !showRanking"
                        >
                        <span style="color:white"> Classificação </span>
                      </v-btn>
                    </v-row>
                    <v-btn 
                      v-if="showRanking"
                      color="#009263"
                      @click="refreshCampeonato">
                      <v-icon color="#FFFFFF">mdi-refresh</v-icon>
                      <span style="color:white">Atualizar</span>
                    </v-btn>
                    <Estatisticas v-if="showStats" :cod="codCampeonato"/>
                    <Ranking v-if="showRanking" :cod="codCampeonato" ref="ranking"/>
                  </div>
                  <div v-else>
                      <v-container align="center" justify="center">
                      <v-img
                      :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                      >
                          <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                              <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                              ></v-progress-circular>
                          </v-row>
                          </template>
                      </v-img>
                      <h2 style="text-align: center">De momento não existe ranking no campeonato ativo.</h2>
                  </v-container>
                  </div> 
                </div>
              </div>
            <div v-else>
              <v-container align="center" justify="center">
                <v-img
                :src="require('@/assets/sad.png')" :lazy-src="require('@/assets/sad.png')" max-height="256" contain class="mt-8 mb-8"
                >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    >
                    <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                </v-img>
                <h2 style="text-align: center">De momento não existe nenhum campeonato ativo.</h2>
              </v-container>
            </div>
          </div>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
    </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require('moment');
import Ranking from "@/components/Ranking.vue";
import Estatisticas from "@/components/Estatisticas.vue";

export default {
    components: {
      Ranking,
      Estatisticas,
    },
    data() {
      return {
        loading: true,
        show: false,
        showStats: false,
        showInfo: false,
        showRanking: false,
        existeCampeonato: false,
        existeRanking: false,
        codCampeonato: null,
        infoCampeonatos: [],
        campeonatoInfo: {},
        multipleCampeonatos: false,
        campeonatos: [],
        edicao: null,
        statsGerais: {
          totalAlunos: 0,
          totalJogos: 0,
          totalAgrupamentos: 0,
          totalMunicipios: 0,
        },
      };
    },
    props: {

    },
    methods: {

      async getStatsCampeonato(){
        const campeonatoData = await axios.get(host + "campeonatos/" + this.codCampeonato)
        const infoEscolas = await axios.get(host + "escolas");
        var escs = infoEscolas.data["escolas"]
        var localidades = []
        var escolas = []
        if(campeonatoData.data != undefined){
          var data = campeonatoData.data
          data.map(obj => {
            let loc = escs.find(e => e.nome == obj.escola).localidade
            if(!(localidades.find(e => e === loc))) localidades.push(loc)
            if(!(escolas.find(e => e === obj.escola))) escolas.push(obj.escola)

            this.statsGerais.totalAlunos += 1
            this.statsGerais.totalJogos += obj.njogos
          })
          this.statsGerais.totalAgrupamentos += escolas.length
          this.statsGerais.totalMunicipios += localidades.length
        }

      },

      refreshCampeonato(){
        this.statsGerais.totalAgrupamentos = 0
        this.statsGerais.totalAlunos = 0
        this.statsGerais.totalJogos = 0
        this.statsGerais.totalMunicipios = 0
        this.getCampeonatoAtivo()
        this.$refs.ranking.getCampeonato()
      },

      getEdicao(date){
          let data = moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY') : 'Indisponível';
          return ("Edição " + data)
      },

      getCampeonatos(){
        return this.campeonatos.map(c => { return c.edicao})
      },

      setCampeonato(){
        this.existeCampeonato = true
        this.existeRanking = true
        this.codCampeonato = this.campeonatos.find(o => o.edicao === this.edicao).cod
        this.campeonatoInfo = this.infoCampeonatos.find(info => info.cod == this.codCampeonato)
        this.getStatsCampeonato()
      },

      async getCampeonatoAtivo(){
        const data = await axios.get(host + 'campeonatos-ids/proximoAtivo' )
        if(data.data["prox"] != undefined){
          if(data.data["prox"].length == 1){
              this.campeonatoInfo = data.data["prox"][0]
              this.existeCampeonato = true
              this.codCampeonato = this.campeonatoInfo["id"]
              this.existeRanking = true
              this.getStatsCampeonato()
              this.loading = false
          }else if(data.data["prox"].length > 1){
              this.existeCampeonato = true 
              this.multipleCampeonatos = true
              this.infoCampeonatos = data.data["prox"]
              this.campeonatos = data.data["prox"].map(c => {
                return {edicao: c.desc, cod: c.id}
              })
              this.loading = false
          }
        }
        else{
          this.loading = false
        }
      },

      getFrase1(){
        return this.campeonatoInfo["frase1"]
      },

      getFrase2(){
        return this.campeonatoInfo["frase2"]
      },
  
      getComunidade(){
        if (this.campeonatoInfo["comunidade"] != null && this.campeonatoInfo["comunidade"] != ''){
            return this.campeonatoInfo["comunidade"]
        }
        else{
          return "Não existe informação relativa a este ponto"
        }
      },

      getMunicipio(){
        if (this.campeonatoInfo["municipio"] != null && this.campeonatoInfo["municipio"] != ''){
            return this.campeonatoInfo["municipio"]
        }
        else{
          return "Não existe informação relativa a este ponto"
        }
      },
      
        
      },

    computed: {

    },
  
    async mounted() {
      this.getCampeonatoAtivo()
    },
    watch: {
    }
  };
</script>

<style scoped>
  .info-box {
    display: flex;
    justify-content: space-around; 
    align-items: center;
    border-radius: 10px;
    border-color: #009263; 
    border-width: 1px; 
    border-style: solid; 
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    /* padding: 16px; */
    margin: auto;
    max-width: 900px;
  }

  .info-col {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: auto;
  }
  
  @media only screen and (max-width: 480px) {
  .info-box {
    flex-direction: column;
  }
}

.title-campeonato{
    color:#009263; 
    max-width: 100%; 
    font-size: 2.5rem;
}

@media screen and (max-width: 320px) {
  .title-campeonato {
    font-size: 1.4rem;
    max-width: 100%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 321px) and (max-width: 378px) {
  .title-campeonato {
    font-size: 1.6rem;
    max-width: 90%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  .title-campeonato {
    font-size: 1.8rem;
    max-width: 90%;
    /* white-space: normal; */
    word-break: break-word;
  }
}

@media screen and (min-width: 415px) and (max-width: 568px) {
  .title-campeonato {
    font-size: 1.8rem;
    max-width: 100%;
    /* white-space: normal; */
    word-break: break-word;
  }
}
</style>