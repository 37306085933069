import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Campeonatos from "@/views/Campeonatos.vue";
import Jogos from "@/views/Jogos.vue"
import CampeonatoEspecifico from "@/views/CampeonatoEspecifico.vue";
import CampeonatoAtivo from "@/views/CampeonatoAtivo.vue";
import Home from "@/views/Home.vue";
import Certificados from "@/views/Certificados.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "Home",
    props: true,
    component: Home,
  },
  {
    path: "/campeonatos",
    name: "Campeonatos",
    props: true,
    component: Campeonatos,
  },
  {
    path: "/jogos",
    name: "Jogos",
    props: true,
    component: Jogos,
  },
  {
    path: "/campeonatos/:codcampeonato",
    name: "CampeonatoEspecifico",
    props: true,
    component: CampeonatoEspecifico,
  },
  {
    path: "/campeonato/ativo",
    name: "CampeonatoAtivo",
    props: true,
    component: CampeonatoAtivo,
  },
  {
    path: "/certificados",
    name: "Certificados",
    props: true,
    component: Certificados,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// caso token seja removido pelo backoffice
router.beforeEach((to, from, next) => {
  const auth = store.getters.isAuthenticated;
  const token = localStorage.getItem("token");
  if (!token && auth) store.dispatch("logout");
  else next();
});

export default router;
